
<b class="request__status__title font-weight-bold mt-3" translate> generic.request</b>

<div class="row mt-1">

    <span class="col-1 pt-2 ">

        <!-- SAP  -->
        <span *ngIf=" (item.status_rc | uppercase) != 'BLOQUEADA'  && item.tipo_processo != '002' && item.tipo_processo != 'COUPA' && (item.cod_eliminacao_rc | uppercase) != 'X'">

            <div *ngIf="!item.status_rc  || !verifyStatus">
                <span class="position-absolute mt-3 ml-3">0/2</span>
                <app-progress size="50" class1="svg-default-green" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
            </div>  
            <div *ngIf="(item.status_rc | uppercase) == 'AGUARDANDO LIBERAÇÃO' " >
                <span class="position-absolute mt-3 ml-3">1/2</span>
                <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-default-green" class4="svg-default-green"></app-progress>
            </div>  
            <div *ngIf="(item.status_rc | uppercase) == 'APROVADA' " >
                <span class="position-absolute mt-3 ml-3">2/2</span>
                <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
            </div>  
            
        </span>
        
        <!-- COUPA  -->
        <span *ngIf="item.tipo_processo == '002' || item.tipo_processo == 'COUPA'">

            <div *ngIf="!item.status_rc || !verifyStatusCoupa">
                <span class="position-absolute mt-3 ml-3">0/4</span>
                <app-progress size="50" class1="svg-default-green" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
            </div>  

            <div *ngIf="(item.status_rc | uppercase) == 'RASCUNHO'  " >
                <span class="position-absolute mt-3 ml-3">1/4</span>
                <app-progress size="50" class1="svg-success" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
            </div>  

            <div *ngIf="(item.status_rc | uppercase) == 'AGUARDANDO LIBERAÇÃO' " >
                <span class="position-absolute mt-3 ml-3">2/4</span>
                <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-default-green" class4="svg-default-green"></app-progress>
            </div>  

            <div *ngIf="(item.status_rc | uppercase) == 'EM PROCESSAMENTO'" >
                <span class="position-absolute mt-3 ml-3">3/4</span>
                <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
            </div>  

            <div *ngIf="(item.status_rc | uppercase) == 'PROCESSADA' " >
                <span class="position-absolute mt-3 ml-3">4/4</span>
                <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
            </div>  
        </span>

        <span *ngIf="item.prazo_entrega_rc && (item.status_rc | uppercase) == 'CANCELADO' && (item.status_rc | uppercase) != 'BLOQUEADA' " >
            <span class="position-absolute mt-3 ml-3">2/2</span>
            <app-progress size="50" class1="svg-danger" class2="svg-danger" class3="svg-danger" class4="svg-danger"></app-progress>

        </span>
        <span *ngIf="!item.prazo_entrega_rc && (item.status_rc | uppercase) == 'CANCELADO' && (item.status_rc | uppercase) != 'BLOQUEADA' " >
            <span class="position-absolute mt-3 ml-3">1/2</span>
            <app-progress size="50" class1="svg-danger" class2="svg-danger" class3="svg-default-danger" class4="svg-default-danger"></app-progress>

        </span>
     
        <span *ngIf="(item.status_rc | uppercase) == 'BLOQUEADA' || item.dt_bloqueio_rc  ">
            <span class="position-absolute mt-3 ml-3">2/2</span>
            <app-progress size="50" class1="svg-danger" class2="svg-danger" class3="svg-danger" class4="svg-danger"></app-progress>
        </span>

    </span>


    <span class="col mt-4 ml-3" *ngIf="(item.status_rc | uppercase) != 'PROCESSADA' && (item.status_rc | uppercase) != 'APROVADA' && item.tipo_processo != 003" >
        <app-progress-line class="svg-default-green" ></app-progress-line>
    </span>

    <span class="col mt-4 ml-3" *ngIf="(item.status_rc | uppercase) == 'PROCESSADA' || (item.status_rc | uppercase) == 'APROVADA' && item.tipo_processo != 003 " >
        <app-progress-line class="svg-success" ></app-progress-line>
    </span>
    
</div>

<!-- COUPA RC-->
<div *ngIf="item.tipo_processo == 002 || item.tipo_processo == 'COUPA'">
   
    <div *ngIf="(item.status_rc | uppercase) == 'RASCUNHO'  ||  (item.status_rc | uppercase ) == 'EM PROCESSAMENTO' || (item.status_rc | uppercase ) == 'PROCESSADA'">
        <div class="row">
            <div class="col">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
        <div class="d-flex" >
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
            <div class="request__status__text ml-2 font-24"  [ngClass]="{'request__status__textCurrent': (item.status_rc | uppercase) == 'RASCUNHO' && (!item.cotacoes?.status_cotacao || !item.cod_material) }">
                <div>
                    <span *ngIf="(item.status_rc | uppercase) == 'RASCUNHO'" translate> request.draft</span> 
                    <span *ngIf="(item.status_rc | uppercase) != 'RASCUNHO'" translate> request.onRelease</span> <br> 
                    <b *ngIf="(item.status_rc | uppercase) != 'RASCUNHO'"><span translate> request.createRc </span> : {{item.dt_criacao_rc | date: 'dd/MM/yyyy'}}</b>

                </div>
            </div>
        </div>
    </div>

    <div *ngIf="(item.status_rc | uppercase ) == 'EM PROCESSAMENTO' || (item.status_rc | uppercase ) == 'PROCESSADA'">
        <div class="row mt-n4">
            <div class="col mt-n1">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
        <div class="d-flex" >
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
            <div class="request__status__text ml-2 mt-n1 font-24"  [ngClass]="{'request__status__textCurrent': (item.status_rc | uppercase) == 'EM PROCESSAMENTO'  && (!item.cotacoes?.status_cotacao || !item.cod_material) }">
                <span translate [ngClass]="{'font-18' : (item.status_rc | uppercase) == 'EM PROCESSAMENTO' && (!item.cotacoes?.status_cotacao || !item.cod_material) }"> request.processing</span> <br>
                <div class="font-weight-bold">
                    <span translate>request.estimatedDelivery</span> {{item.prazo_entrega_rc}}  <span translate>generic.days</span>
                </div>                                               
            </div>
        </div>
    </div>

    <div *ngIf="(item.status_rc | uppercase ) == 'PROCESSADA'">  
        <div class="row mt-n4">
            <div class="col mt-n3">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
        <div class="d-flex" >
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
            <div class="request__status__text ml-2 font-24"  [ngClass]="{'request__status__textCurrent': (item.status_rc | uppercase) == 'PROCESSADA'  &&  (!item.cotacoes.status_cotacao || item.cod_material )}">
                <span [ngClass]="{'font-18': (item.status_rc | uppercase) == 'PROCESSADA' &&  (!item.cotacoes.status_cotacao || item.cod_material )}" translate> request.processed</span> <br>
                <span class="font-weight-bold">{{item.dt_aprovacao_rc  | date: 'dd/MM/yyyy'}}</span>                                                 
            </div>
        </div>
    </div>

</div>
<!-- RC default  -->
<div>

    <div *ngIf="(item.status_rc | uppercase) == 'AGUARDANDO LIBERAÇÃO' || (item.status_rc | uppercase) == 'APROVADA'  || ((item.status_rc | uppercase) == 'BLOQUEADA' && item.dt_criacao_rc) || ((item.status_rc | uppercase) == 'CANCELADO' && item.dt_criacao_rc)">
        <div class="row">
            <div class="col">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>

        <div class="d-flex" >
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
            <div class="request__status__text ml-2 "  [ngClass]="{'request__status__textCurrent': (item.status_rc | uppercase) != 'APROVADA'  && !order?.status_pedido && (item.cod_eliminacao_rc | uppercase) != 'X'  }">
                <span [ngClass]="{'font-18':  (item.status_rc | uppercase) != 'APROVADA' && !order?.status_pedido && (item.cod_eliminacao_rc | uppercase) != 'X' }" translate> request.awaitingRelease</span><br>
                <b><span translate> request.createRc </span> : {{item.dt_criacao_rc | date: 'dd/MM/yyyy'}}</b>
            </div>


        </div>

    </div>

    <div *ngIf="(item.status_rc | uppercase) == 'APROVADA' || ((item.status_rc | uppercase) == 'BLOQUEADA' && item.dt_aprovacao_rc) || ((item.status_rc | uppercase) == 'CANCELADO' && item.dt_aprovacao_rc)">

        <div class="row  mt-n3" >
            <div class="col mt-n4">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
        
        <div class="d-flex" >
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>

            <div class="request__status__text font-24 ml-2" [ngClass]="{'request__status__textCurrent':(item.status_rc | uppercase) == 'APROVADA'  && (item.staus_rc | uppercase) != 'CANCELADO' }"  >
                <span class="font-weight-400" [ngClass]="{'font-18': (item.status_rc | uppercase) != 'CANCELADO'   }" translate> request.approved</span> <br>
                <b>{{item.dt_aprovacao_rc | date: 'dd/MM/yyyy'}}</b>
                <p  *ngIf="(item.status_rc | uppercase) == 'APROVADA'" class="font-weight-bold"><span translate>request.estimatedDelivery</span> : {{item.prazo_entrega_rc}} <span translate>generic.days</span> </p>
            </div>

            
        </div>

    </div>


    <div *ngIf="(item.status_rc | uppercase) == 'BLOQUEADA' || item.dt_bloqueio_rc">

         <div class="row" [ngClass]="{'mt-n5': !item.dt_aprovacao_rc && !item.dt_criacao_rc }">
            <div class="col mt-n5">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
        
        <div class="d-flex" >
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
            <div *ngIf="item.bloqueio_rc">
                <b class="text-danger font-18 ml-1" translate>request.blocked</b>
                <br>
                <span class="text-danger ml-1">{{item.dt_bloqueio_rc | date: 'dd/MM/yyyy'}}</span>
            </div>
        </div>

    </div>



    <div *ngIf="(item.status_rc | uppercase) == 'CANCELADO' && (item.status_rc | uppercase) != 'BLOQUEADA' ">
        <div class="row mt-n4" >
            <div class="col mt-n1">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
        
        <div class="d-flex" >
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
            <div *ngIf="item.bloqueio_rc">
                <b class="text-danger font-18 ml-1" translate>request.canceled</b>
                <br>
                <b class="text-danger ml-1">
                    <span  translate>request.dateCanceled</span>
                    <span> {{item.dt_cancelamento_rc | date: 'dd/MM/yyyy'}}</span>
                </b>

            </div>
        </div>

    </div>
</div>