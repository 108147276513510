import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter} from '@angular/core';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { formatDate } from '@angular/common';
import { AuthService } from './auth.service';
import { switchMap } from 'rxjs/operators';
import { baseAPI } from '@app/constants';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})

export class RequestService {
    itemsRequest: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor(private http: HttpClient, private authService : AuthService) {}

    searchTrackingRequest(data : any) : Observable<any> {      
      
      return this.authService.authToken()
      .pipe(switchMap(auth => {
         
          const headers = new HttpHeaders({
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${auth.data.token}`
          });

          return this.http.get(baseAPI + '/v1/tracking/searchByParamsProc', {headers : headers, params: data.request})

      }))
      
    }

    downloadFile(data : any): Observable<any> {
                  
      return this.authService.authToken()
      .pipe(switchMap(auth => {
          
          const headers = new HttpHeaders({
            'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Authorization': `Bearer ${auth.data.token}`
          });
                 
         

          return this.http.get(baseAPI + '/v1/tracking/BaixarArquivo/', {headers : headers, params: data.request, responseType: 'blob'})
       
        }));
    }



    saveAsExcelFile(url: any): void {
        
      var urlSave = URL.createObjectURL(url);
        
      saveAs(urlSave, 'procurement_tracking_export_' + formatDate(new Date(), 'yyyy_MM_dd', 'en')
        + '.xlsx');      
    
     
    
    

    }
    
 
}