import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { InputService } from '@app/components/form/input.service';
import { RequestService } from '@app/services/request.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as actionRequest from "@store/request/request.actions";
import { rearg } from 'lodash';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { map, takeUntil } from 'rxjs/operators';
import { EventEmitter } from 'stream';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnChanges, OnDestroy{

  basicSearch : Boolean = true;
  hardSearch : Boolean = false;
  sidebarSearch : any = false;
  yes : string = ''
  no : string = '';
  all : string = '';
  itemsPerPage : number  = 10
  currentPage : number = 1
  arrayTags : any[] = []
  arrayTagsLength : number = 0
  plus : boolean = false
  data : any = {};

  formSearch : FormGroup
  @Input() formDataValue?  : any;
  excelTable : any;
  private destroy = new Subject();
  result : any

  readonly requestItems$: Observable<any> = this.store.select('request');
  
  items: any = []
  
  loginDisplay : any;
  qtdItems = 0;
  itemsTest : any [] = []

  constructor(private store: Store<any>,protected translation: TranslateService, private requestService : RequestService, private inputService: InputService, private formBuilder : FormBuilder,  public changeDetectorRef: ChangeDetectorRef,  
    public inputTagService : InputService


 ) { 
    
    this.formSearch = this.formBuilder.group({
      NumRequisicao:  [''],
      NumPedido : [''],
      CNPJ: [''],
      DataPedidoInicial: [''],
      DataPedidoFinal: [''],
      TipoPedido: [''],
      CodFornecedor: [''],
      NomeFornecedor: [''],
      CodCentro: [''],
      RemessaFinal : [''],
      PedidoEliminado : [''],
      CodMaterial: [''],
      NomeMaterial: [''],
      NumPEP: [''],
      inputRef : [''],
      CodNomeFornecedor: [''],
      CodNomeMaterial : ['']
    })


  }

  cleanForm(event :any){
    this.items = [];
    this.qtdItems = this.items.length

      if(event != 1 ){
        this.getItems();
      }
      
    

  }

  ngOnChanges(){


  }
 

  getItems() {     
    this.requestItems$.pipe(takeUntil(this.destroy)).subscribe(((res : any) => { 
      
      if(res != this.result){
        this.result = res

        if(this.result.data){        
          this.result.data.forEach((element : any) => {
        
            if(!element.$ref){
              this.items.push(element);              
            }
          });
         
        }
        this.qtdItems = this.items.length;

        if(!this.result.message?.includes('500 Internal Server Error')){
          
          if(this.result.message){
            Swal.fire(this.result.message,'', this.result.error ? 'error' : 'success');
          }
  
      
        }else{
          Swal.fire('Não foi possível carregar todos os dados. Tente novamente!','','error');
  
        }
  
      }
  
      
    }))

  }

  ngOnDestroy(){
    this.destroy.next(true);
    this.destroy.complete();
  }
  
  removeTag(tag : string){
    return this.inputService.removeTag(tag);
  }  
  
  getTableExcel(value: any) {
    this.excelTable = value;
  }

  exportAsExcelFile() {

    this.formFormat()
    
    
    Object.keys(this.formSearch.value).filter(a => this.formSearch.value[a] !== null && this.formSearch.value[a] !== undefined && this.formSearch.value[a] != '').forEach(key => {     
      if(key != "DataPedidoInicial" && key != "DataPedidoFinal" ){
        this.data[key] = this.formSearch.value[key];      

      }
    });
    
      
    this.store.dispatch(new actionRequest.DownloadFile(this.data));
  
  }


  formFormat(){
    this.data = {}

    if(this.formSearch.controls.CNPJ.value){
      this.data.CNPJ =  this.formSearch.controls.CNPJ.value.replace(/[^\w\s]/gi, '')
    }
    
    if(this.formSearch.controls.DataPedidoInicial.value) {
      this.data.DataPedidoInicial = formatDate(new Date(this.formSearch.controls.DataPedidoInicial.value), 'yyyy-MM-dd', 'en')
    }

    if(this.formSearch.controls.DataPedidoFinal.value ){
      this.data.DataPedidoFinal = formatDate(new Date(this.formSearch.controls.DataPedidoFinal.value), 'yyyy-MM-dd', 'en')
    }
 
    this.data.CodFornecedor = ''
    this.data.NomeFornecedor = '' 

    this.data.CodMaterial = ''
    this.data.NomeMaterial = '' 

    if( this.formSearch.value.CodNomeMaterial ){
      isNaN(this.formSearch.value.CodNomeMaterial.replace('-','')) ? this.data.NomeMaterial = this.formSearch.value.CodNomeMaterial :  this.data.CodMaterial = this.formSearch.value.CodNomeMaterial.replace(/^(0+)(\d)/g,"$2");

    }

    if( this.formSearch.value.CodNomeFornecedor ){

      isNaN(this.formSearch.value.CodNomeFornecedor.replace('-','')) ? this.data.NomeFornecedor  = this.formSearch.value.CodNomeFornecedor :  this.data.CodFornecedor = this.formSearch.value.CodNomeFornecedor.replace(/^(0+)(\d)/g,"$2");
    
    }

    delete this.formSearch.value.inputRef
    delete this.formSearch.value.CodNomeFornecedor
    delete this.formSearch.value.CodNomeMaterial
  }
}
