import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';  
import * as accessManagement from "@store/access-management/access-management.actions";
import * as shippingCompany from "@store/shipping-company/shipping-company.actions";

import { Observable } from 'rxjs';
import { AuthService } from '@app/services/auth.service';
import { ShippingCompanyModel } from '@app/model/shipping-company';
import { AppState } from '@app/app.state';
import { AccessManagementModel } from '@app/model/access-management';



@Component({
  selector: 'app-modal-management',
  templateUrl: './modal-management.component.html',
  styleUrls: ['./modal-management.component.scss']
})
export class ModalManagementComponent implements OnInit {
  formAdd : FormGroup;
  formCompany : FormGroup;

  deleteTitleSwal : string ='';
  cancelSwal : string ='';
  deleteSwal : string ='';
  deleteSuccessSwal : string ='';


  shippingCompany : Boolean  = false;

  readonly filterAccessList$: Observable<AccessManagementModel[]> = this.store.select('accessManagement');
  filterList : AccessManagementModel[] = []

  readonly shippingCompanyList$: Observable<ShippingCompanyModel[]> = this.store.select('shippingCompany');
  shippingCompanyList : ShippingCompanyModel[] = []

  constructor( private formBuilder : FormBuilder, public dialogRef: MatDialogRef<ModalManagementComponent>, protected translation: TranslateService, private store: Store<AppState>, private authService : AuthService) {
    this.formAdd = this.formBuilder.group({
      cod_categoria:  ['', Validators.required], 
      des_categoria : [''], 
      tipo_categoria : ['1']  
    })

    this.formCompany = this.formBuilder.group({
      codTR:  ['', Validators.required], 
      siteTR : ['', Validators.required], 
    })
  }

   ngOnInit() {
    
    this.getList(1)
    this.getShippingCompany();

  }

  cleanFormAdd(){
    this.formAdd.controls.cod_categoria.setValue('')
    this.formAdd.controls.des_categoria.setValue('')
  }
  

  async getList(tipoCategoria : number){  
    this.store.dispatch(new accessManagement.AccessFilters(tipoCategoria));
    
    this.filterAccessList$.subscribe((res : any) => {  
      this.filterList = res.$values;

    }) 
  }


  getShippingCompany(){  
    this.store.dispatch(new shippingCompany.GetAllShippingCompany());
 
    this.shippingCompanyList$.subscribe((res : any) => {  
      this.shippingCompanyList = res.$values
      
    }) 
  }


  async addList(){
    if(this.formAdd.status != "INVALID"){

      this.store.dispatch( new accessManagement.AddAccessFilters(this.formAdd.value))
      this.filterAccessList$.subscribe((res : any) => {  
        this.filterList = res.$values
      }) 
    }
    
  }
 
  async removeList(data? : any, all? : boolean){    

    this.translateSwal();
    const auth: any = await this.authService.authToken().toPromise();

    Swal.fire({  
      title: this.deleteTitleSwal ,  
      icon: 'warning',  
      showCancelButton: true,  
      cancelButtonText: this.cancelSwal,
      confirmButtonText: this.deleteSwal 
 
    }).then((result) => {  
      if (result.value) {  
        
        all ?  this.store.dispatch( new accessManagement.RemoveAllAccessFilters( this.formAdd.value.tipo_categoria)) : this.store.dispatch( new accessManagement.RemoveAccessFilters( data) )
        

      }
    })  
   

  }

  async removeShippingCompanyList(data? : any, all? : boolean){    

    this.translateSwal();

    Swal.fire({  
      title: this.deleteTitleSwal ,  
      icon: 'warning',  
      showCancelButton: true,  
      cancelButtonText: this.cancelSwal,
      confirmButtonText: this.deleteSwal 
 
    }).then((result) => {  
      if (result.value) {  
        all ? this.store.dispatch( new shippingCompany.RemoveAllShippingCompany()) : this.store.dispatch( new shippingCompany.RemoveShippingCompany(data))
      }
    }) 
   

  }

  addListShipping(){
    if(this.formCompany.status != "INVALID"){
      this.store.dispatch( new shippingCompany.AddShippingCompany(this.formCompany.value));  
    }
  }

  translateSwal(){
    this.translation.get("modal.deleteObject").subscribe(res => {
      this.deleteTitleSwal = res
    })

    this.translation.get("modal.cancel").subscribe(res => {
      this.cancelSwal = res
    })

    this.translation.get("modal.delete").subscribe(res => {
      this.deleteSwal = res
    })

    this.translation.get("modal.deleteSuccessSwal").subscribe(res => {
      this.deleteSuccessSwal = res
    })
    
  }
}
