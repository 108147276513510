import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef, OnChanges} from '@angular/core';
import { InputService } from '../form/input.service';
import { Option } from '../form/model/radio.models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnChanges{
  @Output() currentPage : EventEmitter<number> = new EventEmitter<number>() ;
  @Output() itemsPerPage : EventEmitter<number> = new EventEmitter<number>();
  @Input() itemsLength : number = 0
  @Input() sidebar : boolean  = false;
  @Input() items : any;

  totalPages : number = 1;
  page : number = 1;
  itemsPage : number = 10;
  quantityPage : number = 10;
  currentItems : any
  quantityPerPage : Option[] = [
    {code: '10', value: '10'},
    {code: '15', value: '15'},
    {code: '20', value: '20'},
    {code: '25', value: '25'},
  ];


  constructor(private inputService : InputService, private changeDetectorRef: ChangeDetectorRef) { 
  }

  ngOnChanges() {
    this.changeDetectorRef.detectChanges();

    this.currentPage.subscribe((res) => {
      this.page = res
    });  

    this.itemsPerPage.subscribe((res) => {
      this.itemsPage = res
      this.page = 1
    });  
    
    if(this.items != this.currentItems){
      this.page = 1
      this.currentPage.emit(this.page)
      this.currentItems = this.items
    }

    this.calculatePages(this.itemsLength, this.itemsPage)

  }



  calculatePages(itemsLength : number,  itemsPerPage : number){

    if(Math.ceil(itemsLength / itemsPerPage) > 0){      
      this.totalPages =  Math.ceil(itemsLength / itemsPerPage)
    }
    
  }

  onChangeQuantity(){    
    this.calculatePages(this.itemsLength, this.quantityPage)
    this.totalPages < this.page ? this.currentPage.emit(1) : null
    this.itemsPerPage.emit(this.quantityPage);
    this.inputService.checkboxSelect.emit([])
  }
 
  next(){
    this.totalPages > this.page ? this.currentPage.emit(this.page + 1) : null;
  }

  previous(){
    this.page > 1 ? this.currentPage.emit(this.page - 1) : null;
  }
  
}
