<footer class="page-footer pt-3 pl-5 pr-5 font-small white w-100">
    <div class="row">
        <span style="width: 350px;" *ngIf="sidebar"></span>

        <div class="col-lg col-sm-12 col-md-12  mb-md-2 mb-2 mb-md-0" >
            <div class="d-flex justify-content-md-start justify-content-center">
                <span class="pl-4 page-footer__linePerPage" translate> footer.linePerPage</span>:
                <select class="form-select quantity-page-select border-0 bg-white" (change)="onChangeQuantity()" 
                [(ngModel)]="quantityPage" >
                    <option  [attr.value]="option.code" *ngFor="let option of quantityPerPage;">
                        {{option.value}}
                    </option>
                </select>
            </div>
        </div>

        <div class="col-lg col-sm-12 col-md-12">
            <ul class="pagination justify-content-md-end justify-content-center ">
                <li class="mr-2 c-pointer page-item" > 
                    <a (click)="previous()" >
                        <app-arrow-left  size="16" class="svg-stroke-secondary"></app-arrow-left>
                    </a>
                </li>
                <li> 
                    <span translate> footer.page </span> {{page}} <span translate> generic.to </span> {{totalPages}}
                </li>
                <li class="ml-2 pr-4 c-pointer page-item">
                    <a (click)="next()" >
                        <app-arrow-right size="16" class="svg-stroke-secondary"></app-arrow-right>
                    </a>
                </li>
            </ul>
        </div>
        
    </div>    
</footer>