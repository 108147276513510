import { ShippingCompanyModel } from "@app/model/shipping-company";
import * as shippingCompanyReducer from "./shipping-company.actions";



export function ShippingCompanyReducer (  state : ShippingCompanyModel[] = [], action: any) {

    switch (action.type) {
 
        case shippingCompanyReducer.ALL_SHIPPING_COMPANY_SUCCESS:
            return action.shippingCompany 
    
        case shippingCompanyReducer.GET_SHIPPING_COMPANY_SUCCESS:
            
            return action.shippingCompany
      
        default:
            return state




    }
}


