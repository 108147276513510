import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackingRequestsComponent } from './tracking-requests.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@app/translator';
import { HttpClient } from '@angular/common/http'
import { ComponentsModule } from '../components.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { RequestFlowComponent } from './status-flow/request-flow/request-flow.component';
import { QuoteFlowComponent } from './status-flow/quote-flow/quote-flow.component';
import { OrderFlowComponent } from './status-flow/order-flow/order-flow.component';
import { TrafficFlowComponent } from './status-flow/traffic-flow/traffic-flow.component';
import { ReceivingFlowComponent } from './status-flow/receiving-flow/receiving-flow.component';
import { RequestStatusComponent } from './current-status-header/request-status/request-status.component';
import { QuoteStatusComponent } from './current-status-header/quote-status/quote-status.component';
import { OrderStatusComponent } from './current-status-header/order-status/order-status.component';
import { TrafficStatusComponent } from './current-status-header/traffic-status/traffic-status.component';
import { ReceivementStatusComponent } from './current-status-header/receivement-status/receivement-status.component';
import { intPipe } from '../pipes/intPipe.pipe';


@NgModule({
  declarations: [
    TrackingRequestsComponent,
    RequestDetailsComponent,
    RequestFlowComponent,
    QuoteFlowComponent,
    OrderFlowComponent,
    TrafficFlowComponent,
    ReceivingFlowComponent,
    RequestStatusComponent,
    QuoteStatusComponent,
    OrderStatusComponent,
    TrafficStatusComponent,
    ReceivementStatusComponent,
    intPipe

  ],
  imports: [
    CommonModule,
    ComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxPaginationModule,
  ],
  providers:[
    intPipe
  ],
  exports: [
    RequestDetailsComponent,
    TrackingRequestsComponent,
    RequestFlowComponent,
    QuoteFlowComponent,
    OrderFlowComponent,
    TrafficFlowComponent,
    ReceivingFlowComponent
  ]
})
export class TrackingRequestsModule {
  constructor() {

   }
   
 }