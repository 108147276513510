import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as requestActions from "./request.actions";
import { map,tap, mergeMap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { RequestService } from "@app/services/request.service";
import { LoadingService } from "@app/services/loading.service";
import Swal from "sweetalert2";

@Injectable()
export class RequestEffects {

    SearchRequest$ = createEffect(() => 
        this.actions$.pipe(
            ofType<requestActions.Request>(requestActions.REQUEST),
            mergeMap(
                data => this.requestService.searchTrackingRequest(data).pipe(
                    map(res => new requestActions.RequestSuccess(res) ),
                    catchError( error => of (new requestActions.RequestError(error)))
                )
            )

        )

    )
 

    DownloadFile$ = createEffect(() => 
        this.actions$.pipe(
            ofType<requestActions.DownloadFile>(requestActions.DOWNLOAD_FILE),
            mergeMap(
                data => this.requestService.downloadFile(data).pipe(
                    map((res : any)=> new requestActions.DownloadSuccess(res) ),
                    catchError( error => of (new requestActions.DownloadError(error)))
                )
            )

        )

    ).subscribe(val => {
        if(val.payload.message){
            
            Swal.fire(val.payload.message,'', 'error');

        }else{
            this.requestService.saveAsExcelFile(val.payload);

        }
    });
    

  constructor(private actions$: Actions, private requestService : RequestService, public loader: LoadingService) {
      
  }

}