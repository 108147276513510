import { Inject, Injectable } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MicrosoftService {

  constructor(
      private authService: MsalService,@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,private msalBroadcastService: MsalBroadcastService
  ) {     
  }

  
    login(){
    
        const account : any = this.authService.instance.getAllAccounts();
    
        if(account.length == 0){
            this.msalBroadcastService.inProgress$
            .pipe(
              filter((status: InteractionStatus) => status === InteractionStatus.None),
            )
            .subscribe(() => {
                // redirect anonymous user to login page 
                this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
            })
        
        }else{
            localStorage.setItem('email', account[0].username)
            if(account[0].idTokenClaims.roles){
                localStorage.setItem('groups', JSON.stringify(account[0].idTokenClaims.roles))
            }else{
                localStorage.setItem('groups', JSON.stringify(account[0].idTokenClaims.groups))
            }
        }
  
     }

    logoff(){
        this.authService.logoutRedirect({
             postLogoutRedirectUri: 'https://procurementtracking.braskem.com.br'
        });
    }

    checkUser(){
        this.msalBroadcastService.msalSubject$
            .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            )
            .subscribe((result: any) => {
            
            if(result.payload.account){   
                localStorage.setItem('account', result.payload.account)                      
                localStorage.setItem('email', result.payload.account.username)
                if(result.payload.account.idTokenClaims.roles){
                    localStorage.setItem('groups', JSON.stringify(result.payload.account.idTokenClaims.roles))
                }else{
                    localStorage.setItem('groups', JSON.stringify(result.payload.account.idTokenClaims.groups))
                }
            }
        });
    }
  
}
