import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { InputService } from '../input.service';


@Component({
    selector: 'app-date-picker-range',
    templateUrl: './datepicker-range.component.html',
    styleUrls: ['./datepicker-range.component.scss'],
    providers: [
     ],
})

export class DatepickerRangeComponent implements OnInit {
    @Input() form!: FormGroup;
    @Input() id!: string;
    @Input() name!: string;
    @Input() label?: string
    @Input() type?: string = 'text';
    @Input() required!: string;
    @Input() customErrorMessage!: string;
    @Input() placeholder!: string;
    @Input() containerClass?: string;
    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() startDate: string = "";
    @Input() endDate: string = "";

    hasError: boolean = false;
    errorDetail : string =""
  
    constructor(private translate: TranslateService, private inputService: InputService, private dateAdapter: DateAdapter<any>, public datepipe: DatePipe) { }
    
    
    ngOnInit(): void {       

        this.dateAdapter.setLocale(this.translate.langs[0]);

        this.placeholder && this.translate.get(this.placeholder).subscribe(res => this.placeholder = res);
        this.label && this.translate.get(this.label).subscribe(res => this.label = res);
    }


    getError (field: any, form: any) : string {
        const errors = this.getErrors(field, form);
        this.hasError = errors.length > 0;
        return errors[0];
    }
    
    getErrors (field: any, form: any) : string [] {
        return this.inputService.getErrors(field, form);
    }
    
    classError(field : any, form: any) {
        return {
            'is-invalid': this.getError(field, form),
            'has-feedback': this.hasError
        }
    }
    
}
