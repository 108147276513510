<div class="form-group {{containerClass}}" [ngClass]="{'has-label': label}" [formGroup]="form">
   
    <label [attr.for]="id" class="{{labelClass}}" *ngIf="label">
        {{label}} <span *ngIf="required" class="text-danger">*</span>
    </label>

    <div class="input-group" [ngClass]="{'border-danger': hasError}">
        
        <input 
        [attr.id]="id" 
        [formControlName]="name" 
        class="form-control {{inputClass}}"
        [ngClass]="classError()"
        [attr.type]="type" 
        [attr.placeholder]="placeholder" 
        [attr.autocomplete]="autocomplete"
        [mask]="mask"
        [dropSpecialCharacters]="false"
        [attr.maxLength]="maxLength"
        />
        
        <div class="input-group-append" *ngIf="hasIcon" >
            <div class="input-group-text fix-border-radius border-0 bg-transparent">
                <ng-content></ng-content>
            </div>
        </div>
    
      
    
    </div>

    <div class="invalid-feedback d-block" >
        {{ getError() }}
    </div>
  
  
</div>