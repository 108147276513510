<div class="d-flex flex-column flex-shrink-0 p-3 mt-n3 sidebarSearch overflow bg-white"  *ngIf="visible">
        <div class="row mt-2 mb-1">
            <div class="col-10">
                <span class="sidebarSearch__title font-weight-bold" translate> search.searchRequests </span>
            </div>
    
            <div class="col-1 mt-2 text-right c-pointer" (click)="close()">                
                <app-close size="20" class="svg-stroke-secondary"></app-close>
            </div>
        </div>
    
        <form [formGroup]="formSearch" class="form ml-2 mr-2">

            <div class="row">
                <div class="col-12">
                    <app-input-tag inputClass="search-input"
                    [info]="true"
                    inputId="requestcod"
                    type="text"
                    [arrayBoolean]="true"
                    [pattern]="regex_string"
                    [spaceTag]="true"
                    tooltipText1="{{ 'search.textTooltip1' | translate}}"
                    tooltipText2="{{ 'search.textTooltip2' | translate}}"
                    tooltipText3="{{ 'search.textTooltip3' | translate}}"
                    id="NumRequisicao" placeholder="{{ 'search.enterNumberRequest' | translate}}"
                    labelClass="search__form-group__font-label font-weight-bold"
                    label="{{ 'search.numberRequest' | translate}}"
                    name="NumRequisicao" 
                    countTag="0"
                    [form]="formSearch">
                    </app-input-tag>
                </div>
            </div>
       
            <div class="row">
                <div class="col-12">
                    <app-input-tag inputClass="search-input"
                    [info]="true"
                    [pattern]="regex_string"
                    tooltipText1="{{ 'search.textTooltip1' | translate}}"
                    tooltipText2="{{ 'search.textTooltip2' | translate}}"
                    tooltipText3="{{ 'search.textTooltip3' | translate}}"
                    [spaceTag]="true"
                    [arrayBoolean]="true"
                    id="NumPedido" placeholder="{{ 'search.enterOrderNumber' | translate}}"
                    labelClass="search__form-group__font-label font-weight-bold"
                    label="{{ 'search.orderNumber' | translate}}"
                    countTag="0"
                    name="NumPedido" [form]="formSearch">
                    </app-input-tag>
                </div>
            </div>
      
            <div class="row">
                <div class="col-12">
                    <app-input-tag inputClass="search-input"
                    id="CNPJ" placeholder="{{ 'search.enterCNPJ' | translate}}"
                    labelClass="search__form-group__font-label font-weight-bold"
                    label="{{ 'generic.cnpj' | translate}}"
                    [mask]="mask_cnpj"
                    [spaceTag]="true"
                    [arrayBoolean]="false"
                    countTag="0"
                    name="CNPJ" [form]="formSearch">
                    </app-input-tag>
                </div>
            </div>
    
            <div class="row">
                <div class="col-12">
                    <app-input-tag  inputClass="search-input"
                    id="NumPEP"
                    [arrayBoolean]="false"
                    [spaceTag]="true"
                    labelClass="search__form-group__font-label font-weight-bold"
                    label="{{ 'search.PEPelement' | translate}}"                
                    placeholder="{{ 'generic.enterCode' | translate}}"
                    countTag="0"
                    name="NumPEP" [form]="formSearch">
                    </app-input-tag>
                </div>
            </div>
    
            <div class="row">
                <div class="col-12">
                    <app-date-picker-range id="dateRequest"
                    labelClass="search__form-group__font-label  font-weight-bold"
                    label="{{ 'search.dateRequest' | translate}}"
                    inputClass="search-input"
                    startDate="DataPedidoInicial"
                    endDate="DataPedidoFinal"
                    placeholder="{{ 'search.selectDate' }}" [form]="formSearch" ></app-date-picker-range>
                </div>
            </div>
    
            <div class="row">
                <div class="col-12">
                    <app-input-tag  inputClass="search-input"
                    id="TipoPedido"
                    [spaceTag]="true"
                    [arrayBoolean]="false"
                    labelClass="search__form-group__font-label font-weight-bold"
                    label="{{ 'generic.typeOrder' | translate }}"                
                    placeholder="{{ 'search.typeRequestSelect' | translate }}"
                    countTag="0"
                    name="TipoPedido" [form]="formSearch">
                    </app-input-tag>
                </div>
            </div>
              
            <div class="row">
                <div class="col-12">
                    <app-input-tag  inputClass="search-input"
                    id="CodNomeFornecedor"
                    [arrayBoolean]="false"
                    labelClass="search__form-group__font-label font-weight-bold"
                    label="{{ 'generic.provider' | translate}}"                
                    placeholder="{{ 'search.enterProvider' | translate}}"
                    countTag="0"
                    name="CodNomeFornecedor" [form]="formSearch">
                    </app-input-tag>
                </div>
            </div>
    
    
            <div class="row">
                <div class="col-12">
                    <app-input-tag inputClass="search-input"
                    id="CodNomeMaterial"
                    [arrayBoolean]="false"
                    labelClass="search__form-group__font-label font-weight-bold"
                    label="{{ 'generic.material' | translate}}"                
                    placeholder="{{ 'search.materia| translate}}"     
                    countTag="0"           
                    placeholder="{{ 'search.materialCod' | translate}}"
                    name="CodNomeMaterial" [form]="formSearch">
                    </app-input-tag>
                </div>
            </div>
    
            <div class="row">
                <div class="col-12">
                    <app-input-tag inputClass="search-input"
                    id="CodCentro"
                    [spaceTag]="true"
                    [arrayBoolean]="false"
                    labelClass="search__form-group__font-label font-weight-bold"
                    label="{{ 'generic.center' | translate}}"   
                    countTag="0"             
                    placeholder="{{ 'generic.enterCode' | translate}}"
                    name="CodCentro" [form]="formSearch">
                    </app-input-tag>
                </div>
            </div>
    
            <hr>
    
            <div class="row">
                <div class="col-12">
                    <app-radio id="requestFinalShipmmentRadio" name="RemessaFinal"
                    containerClass="form-group search__form-group"
                    checkClass="d-flex"
                    groupClass="mr-3"
                    countTag="0"
                    labelClass="search__form-group__font-label font-weight-bold"
                    inputClass=" rounded-circle mr-3"
                    label="{{ 'search.requestFinalShipmmentRadioLabel' | translate}}" [options]="finalShipmentOptions" [form]="formSearch">
                    </app-radio>
                </div>
            </div>
    
             <div class="row">
                 <div class="col-12">
                    <app-radio id="deletedOrdersRadio" name="PedidoEliminado"
                    containerClass="form-group search__form-group mr-3"
                    checkClass="d-flex"
                    groupClass="mr-3"
                    countTag="0"
                    labelClass="search__form-group__font-label font-weight-bold mr-3 "
                    inputClass="rounded-circle mr-3"
                    label="{{ 'search.deletedOrdersRadioLabel' | translate}}" [options]="deletedOrdersOptions" [form]="formSearch">
                    </app-radio>
                 </div>
             </div>
    
             <button class="d-none reset" #resetClick type="reset" ></button>

            
        </form>
        <div class="sidebarSearch__footer ">
            <div class="col mt-2 mb-1">
               <button type="button" (click)="onSubmit()" class="btn btn-primary float-right ml-2 mr-2" translate>
                   <app-search size="18" class="svg-stroke-light"></app-search>
                   search.search 
               </button>
               <button class="btn btn-default btn-clean float-right border-0" type="button" (click)="reset()" translate>
                   <app-close size="18" class="svg-stroke-secondary"></app-close>
                   search.cleanFilter
               </button>
            </div>
        </div>
</div>