<b class="request__status__title font-36 font-weight-bold mt-3" translate> generic.quotation</b>
                       
<div class="row mt-1">

    <span class="col-1 pt-2" >
        <div *ngIf="!verifyStatus || !item.cod_contrato_req  && !order?.cod_contrato_pedido && !item?.cotacoes?.cod_contrato && !item.cotacoes?.status_cotacao  && item.grupo_compra_rc != 'INV'">
          <span class="position-absolute mt-3 ml-3">0/3</span>
            <app-progress size="50" class1="svg-default-green" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </div>

        <div *ngIf="((item.cotacoes?.status_cotacao | uppercase) == 'VERIFICANDO SOBRA DE ESTOQUE' || item.grupo_compra_rc == 'INV') && !order?.cod_contrato_pedido &&  (item.cotacoes?.status_cotacao | uppercase) != 'MATERIAL POSSUI CONTRATO' && (item.cotacoes?.status_cotacao | uppercase) != 'COTAÇÃO ENCERRADA' ">
            <span class="position-absolute mt-3 ml-3">1/4</span>
            <app-progress size="50" class1="svg-success" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </div>



        <div *ngIf="(item.cotacoes?.status_cotacao | uppercase ) == 'NÃO INICIADO'">
            <div *ngIf="item.grupo_compra_rc == 'INV'">
                <span class="position-absolute mt-3 ml-3" >2/4</span>
                <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-default-green" class4="svg-default-green"></app-progress>
            </div>

            <div *ngIf="item.grupo_compra_rc != 'INV'">
                <span class="position-absolute mt-3 ml-3">1/3</span>
                <app-progress size="50" class1="svg-success" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
            </div>

        </div>

        
        <div *ngIf="(item.cotacoes?.status_cotacao | uppercase ) == 'EM COTAÇÃO' && !order?.cod_contrato_pedido && !item.cotacoes?.cod_contrato && !item.cod_contrato_req  ">
            <span class="position-absolute mt-3 ml-3">2/3</span>
            <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-default-green"></app-progress>
        </div>

        <div *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA' || (item.cotacoes?.status_cotacao | uppercase) == 'MATERIAL POSSUI CONTRATO'  || item.cod_contrato_req || order?.cod_contrato_pedido || item.cotacoes?.cod_contrato">
            <span class="position-absolute mt-3 ml-3" *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'MATERIAL POSSUI CONTRATO' ||  order?.cod_contrato_pedido ||  item.cotacoes?.cod_contrato || item.cod_contrato_req">2/2</span>
            <span class="position-absolute mt-3 ml-3" *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA' && item.grupo_compra_rc != 'INV'  ">3/3</span>
            <span class="position-absolute mt-3 ml-3" *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA' && item.grupo_compra_rc == 'INV'  ">4/4</span>
            <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
        </div>
    </span>

    <span *ngIf="(item.cotacoes?.status_cotacao | uppercase) != 'COTAÇÃO ENCERRADA' && !item.cod_contrato_req && !order?.cod_contrato_pedido && !item.cotacoes?.cod_contrato  " class="col mt-4 ml-3" >
        <app-progress-line class="svg-default-green" ></app-progress-line>
    </span>

    <!-- || item.cotacoes?.dt_encerramento_cotacao -->
    <span *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA' || (item.cod_contrato_req ||  order?.cod_contrato_pedido  ||  item.cotacoes?.cod_contrato) " class="col mt-4 ml-3" >
        <app-progress-line class="svg-success" ></app-progress-line>
    </span>
</div>

<!-- check stock -->
<div *ngIf="item.grupo_compra_rc == 'INV' || item.cod_contrato_req || order?.cod_contrato_pedido ||  item.cotacoes?.cod_contrato">
    
    <div class="row">
        <div class="col">
            <app-vertical-line size="55" class="svg-success"></app-vertical-line>
        </div>
    </div>

    <div class="d-flex">
        <div class="pl-4 mt-n2">
            <app-circle size="8" class="svg-success"></app-circle>
        </div>
        <div class="request__status__text font-24 ml-2" [ngClass]="{'request__status__textCurrent': item.grupo_compra_rc == 'INV'  && (item.cotacoes?.status_cotacao | uppercase) != 'NÃO INICIADA' && !item.cotacoes?.dt_prevista_cotacao  && !item.cotacoes?.cod_contrato }">
            <span translate>request.checkingStock</span>
         
        </div>
    </div>
    
</div>

<div>
    <!-- not started -->
    <span *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'NÃO INICIADO' || item.cotacoes?.cod_contrato || (item.cotacoes?.status_cotacao | uppercase) == 'EM COTAÇÃO' ||  (item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA' ">
        <div class="row mt-n1">
            <div class="col">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
        <div class="d-flex">
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
            <div class="request__status__text font-24 ml-2"  [ngClass]="{'request__status__textCurrent': (item.cotacoes?.status_cotacao | uppercase) == 'NÃO INICIADO'}">
                <span [ngClass]="{'font-18' : (item.cotacoes?.status_cotacao | uppercase) == 'NÃO INICIADO'}" translate>request.notStarted</span>
            </div>
        </div>
    </span>

    
    <!-- material has contract -->
    <div *ngIf="item.cod_contrato_req || order?.cod_contrato_pedido ||  item.cotacoes?.cod_contrato">
        <div class="row mt-n2">
            <div class="col">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
        <div class="d-flex">
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
            <div class="request__status__text request__status__textCurrent  ml-2"  >
                <span class="font-18 " translate>request.materialContract</span>
            </div>
        </div>
    </div>

    <!-- in quotation -->
    <div *ngIf="((item.cotacoes?.status_cotacao | uppercase) == 'EM COTAÇÃO'  || (item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA') && !order?.cod_contrato_pedido && !item.cotacoes?.cod_contrato && !item.cod_contrato_req  ">

        <div class="row mt-n3">
            <div class="col">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
        <div class="d-flex">
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
            <div class="request__status__text  mt-n2  ml-2" [ngClass]="{'request__status__textCurrent' : (item.cotacoes?.status_cotacao | uppercase) == 'EM COTAÇÃO' }">
                <span translate [ngClass]="{'font-18' : !item.cotacoes?.dt_encerramento_cotacao }">request.inQuotation</span>
                <br>
                <div class="font-weight-bold" *ngIf="!item.cotacoes?.dt_encerramento_cotacao">
                    <span translate>request.quotationForecast</span> {{item.cotacoes?.dt_prevista_cotacao | date: 'dd/MM/yyyy'}}    
                </div>
                <div class="font-weight-bold" *ngIf="item.cotacoes?.dt_prevista_cotacao">
                    {{item.cotacoes?.dt_prevista_cotacao | date: 'dd/MM/yyyy'}}    
                </div>

            </div>
        </div>
    </div>

    <!-- winning Quote  -->
    <!-- || (item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA' -->
    <div *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA'  ">
        <div class="row mt-n4">
            <div class="col">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
        <div class="d-flex">
            <div class="pl-4 mt-n2">
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
            <!-- || item.cotacoes?.dt_encerramento_cotacao  && !order?.cod_liberacao_pedido  -->
            <!-- (item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA' -->
             <div class="request__status__text font-24 ml-2" [ngClass]="{'request__status__textCurrent' : (item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA'  } ">
                
                <span [ngClass]="{'font-18' : (item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA' }" translate>request.quoteClose</span>
                <br>
                <div [ngClass]="{'font-14' : item.cotacoes?.dt_premiacao  && !order?.cod_liberacao_pedido }" class="font-weight-bold">                                        
                    <span translate>request.awardDate</span> : 
                    {{item.cotacoes?.dt_encerramento_cotacao |  date: 'dd/MM/yyyy'}}
                </div> 
            </div> 
        </div>  
    </div>
</div>