<header>
    <nav class="navbar navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-expand-xs position-absolute justify-content-between navbar__shadow" style="cursor:pointer;">
        <a class="navbar-brand img-responsive" href="#"><img src="{{ urlLogo }}" /></a>
        <div class="navbar-nav mr-auto">
            <div class="nav-item ml-3 d-none d-md-block d-lg-block d-sm-block" >
                <b class="title font-16"> Procurement Tracking </b>
            </div>
        </div>
        
        <div class="d-flex">
            <!-- <div class="mr-3" >
                <div class="btn-group">
                    <button class="btn btn-outline-info btn-sm d-flex mt-2 " (click)="updateNotification()">
                        <app-bell  class="svg-stroke-secondary" size="18"></app-bell>
                        <span *ngIf="notify" class="notify"></span>
                    </button> 
                </div>
            </div> -->

            <div class="mr-3">
                <div class="btn-group">
                    <a target='_blank' href="https://braskemsa.sharepoint.com/:f:/r/sites/TS-GPSS/Documentos%20Compartilhados/Tracking%20Treinamento?csf=1&web=1&e=MARa0i" class="btn btn-outline-info btn-sm d-flex mt-2 pb-1">
                        <app-interrogation  class="svg-secondary" size="14"></app-interrogation>
                    </a> 
                </div>
            </div>

            <div>
                <button class="btn btn-outline-info menuUser btn-font btn-sm btn-user d-flex mt-2 pb-1 c-pointer" (click)="dropUser = !dropUser">
                    <b class="mr-3 d-lg-block d-none">{{email}}</b> 
                    <span class="d-block d-lg-none"><app-user size="18" class="svg-stroke-secondary"></app-user></span>
                    <app-arrow-down size="12" class="svg-stroke-secondary"></app-arrow-down>
                </button> 
            </div>
        
            <div  *ngIf="dropUser" class="drop font-14 drop__user  animate__animated animate__fadeInmt-1 mt-5" >
                <a class="dropdown-item li-drop" *ngIf="manageFilters" (click)="openDialog()" translate href="#">header.manageFilters</a>
                <a class="dropdown-item  li-drop" translate href="#" (click)="logout()">header.logout</a>
            </div>

        </div>

    </nav>


    
</header>

