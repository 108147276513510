import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as accessManagementActions from "./access-management.actions";
import { map, mergeMap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { AccessManagementService } from "@app/services/access-management.service";
import { AccessManagementModel } from "@app/model/access-management";
import Swal from "sweetalert2";


@Injectable()
export class AccessManagementEffects {

    getFilters$ = createEffect(() => 
            
        this.actions$.pipe(
            ofType<accessManagementActions.AccessFilters>(accessManagementActions.ACCESS_FILTERS, accessManagementActions.ADD_ACCESS_FILTERS_SUCCESS, accessManagementActions.REMOVE_ALL_ACCESS_FILTERS_SUCCESS, accessManagementActions.REMOVE_ACCESS_FILTERS_SUCCESS ),
            mergeMap(
                (data) => this.accessManagementService.getAccessFilters(data).pipe(
                    map((res : any) => new accessManagementActions.AccessFiltersSuccess(res.data) ),
                    catchError( error => of (new accessManagementActions.AccessFiltersError(error)))
                )
            )

        )

    )

    addFilters$ = createEffect(() => 
    this.actions$
        .pipe (
            ofType<accessManagementActions.AddAccessFilterSuccess>( accessManagementActions.ADD_ACCESS_FILTERS ),
            mergeMap(
               (data) => this.accessManagementService.addAccessFilters(data)
                .pipe(
                    map((res: any) => { 
                        Swal.fire(res.message,'', 'success')
                        return new accessManagementActions.AddAccessFilterSuccess(res.data)}),
                    
                    catchError((error: any) => { 
                      Swal.fire(error.error.message,'', 'error');
                      return of ( new accessManagementActions.AddAccessFiltersError(error));
                    })
                  ),
                ),
            ),
        )
     



     removeFilters$ = createEffect(() => 
     this.actions$
         .pipe (
             ofType<accessManagementActions.RemoveAccessFilters>( accessManagementActions.REMOVE_ACCESS_FILTERS ),
             mergeMap(
                 data => this.accessManagementService.removeAccessFilters(data)
                 .pipe(
                    map((res: any) => { 
                        Swal.fire(res.message,'', 'success')
                        return new accessManagementActions.RemoveAccessFilterSuccess(res.data)}),
                    
                    catchError((error: any) => { 
                      Swal.fire(error.error.message,'', 'error');
                      return of ( new accessManagementActions.RemoveAccessFiltersError(error));
                    })
                  ),
                 ),
             ),
         )

      removeAllFilters$ = createEffect(() => 
      this.actions$
          .pipe (
              ofType<accessManagementActions.RemoveAllAccessFilters>( accessManagementActions.REMOVE_ALL_ACCESS_FILTERS ),
              mergeMap(
                  data => this.accessManagementService.removeAllAccessFilters(data)
                  .pipe(
                    map((res: any) => { 
                        Swal.fire(res.message,'', 'success')
                        return new accessManagementActions.RemoveAllAccessFilterSuccess(res.data)}),
                    
                    catchError((error: any) => { 
                      Swal.fire(error.error.message,'', 'error');
                      return of ( new accessManagementActions.RemoveAllAccessFiltersError(error));
                    })
                  ),
              ),
          )
       );

 
  constructor(private actions$: Actions, private accessManagementService : AccessManagementService) {      
  }

}