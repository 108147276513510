import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter, Output} from '@angular/core';
import { Observable } from 'rxjs';
import { tap,map, switchMap } from 'rxjs/operators';
import { AccessManagementModel } from '@app/model/access-management';
import { baseAPI } from '@app/constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class AccessManagementService {


    constructor(private http: HttpClient, private authService : AuthService) {

    }

    getAccessFilters(data : any) : Observable<AccessManagementModel> {
        
        var categoria = 0
        if(data.tipo_categoria){
            categoria = data.tipo_categoria

        }else if(data.filters.tipo_categoria){
            categoria = data.filters.tipo_categoria
        }

        return this.authService.authToken()
        .pipe(switchMap(auth => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${auth.data.token}`
            });

           return  this.http.get(baseAPI + '/v1/filtro/get-all/'+categoria, {headers : headers})
        }))
    }
 
      
    addAccessFilters (filter: any): Observable<AccessManagementModel>  {    
       
        return this.authService.authToken()
        .pipe(switchMap(auth => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${auth.data.token}`
            });

            return this.http.post(baseAPI + '/v1/filtro/create', filter.filters, {headers : headers})
        }))
    
    }


    
    removeAccessFilters(data : any) : Observable<any>{
    
        return this.authService.authToken()
        .pipe(switchMap(auth => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${auth.data.token}`
        });
            return this.http.delete(baseAPI + '/v1/filtro/remove/'+data.filters.tipo_categoria+'/'+data.filters.cod_categoria,{headers : headers})
        }))
    }

    removeAllAccessFilters(data : any) : Observable<AccessManagementModel>{
        
        return this.authService.authToken()
        .pipe(switchMap(auth => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${auth.data.token}`
            });
        
            return this.http.delete(baseAPI + '/v1/filtro/removeAll/'+data.tipo_categoria ,{headers : headers})
        }));
    }

 
}