<div  class="form-group date-picker-custom {{containerClass}} {{label ? 'has-label' : ''}}" [formGroup]="form" [attr.data-uid]="name">
    <label [attr.for]="id" class="{{labelClass}}" *ngIf="label"> {{label}} {{required ? '*' : ''}}</label>
    
    <div appearance="fill">

        <mat-datepicker-toggle class="icon-calendar " matSuffix [for]="picker">
            <mat-icon  matDatepickerToggleIcon>
                <app-calendar size="18" class="svg-stroke-secondary"></app-calendar>
            </mat-icon>
        </mat-datepicker-toggle>
     
     
        <mat-date-range-input
        mask="d0/M0/0000"
        [ngClass]="{'border-danger': hasError}"
        class="form-control {{inputClass}}"
        [rangePicker]="picker" 
        >
         <input matStartDate
         [ngClass]="{'mat-date-start-true' : form.controls[startDate].value && form.controls[endDate].value, 'mat-not-date-start' : !form.controls[startDate].value && !form.controls[endDate].value }" placeholder="{{ !form.controls[startDate].value && !form.controls[endDate].value ? placeholder : null}}"  [formControlName]="startDate">
         <input matEndDate   [formControlName]="endDate">
        </mat-date-range-input>

        <mat-date-range-picker #picker></mat-date-range-picker>
        
        <div class="invalid-feedback d-block" *ngIf="hasError" >
            {{ errorDetail }}
        </div>
    </div>



      
    
</div>
