import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { iteratee } from 'lodash';
import { InputService } from '../input.service';

@Component({
    selector: 'app-input-tag',
    templateUrl: './input-tag.component.html',
    styleUrls: ['./input-tag.component.scss']
})

export class InputTagComponent implements OnInit {
    @Input() form!: FormGroup;
    @Input() id!: string;
    @Input() name!: string;
    @Input() label?: string;
    @Input() val? : string = '';
    @Input() info?: boolean = false;
    @Input() tooltipText1?: string;
    @Input() tooltipText2?: string;
    @Input() tooltipText3?: string;
    @Input() spaceTag?: boolean = false;
    @Input() arrayBoolean : boolean = false
    @Input() countTag : any = 0
    inputName: any
    @Input() type?: string = 'text';
    
    tags?: any[] = []
    @Input() arrayTags: any[] = [];
    @Input() nameEvent?: any
    value : any[] = []

    @Input() required!: string;
    @Input() customErrorMessage!: string;
    @Input() autocomplete?: string;
    @Input() placeholder!: string;
    @Input() maxLength: number = 255;
    @Input() containerClass?: string;
    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() inputId?: string;

    @Input() mask: string = '';
    @Input() pattern?: RegExp;

    @Input() hasIcon?: boolean;
    @Input() infoIcon?: string;
    
    @ViewChild('myInput') myInput?: ElementRef<HTMLElement>;
    
    hasError: boolean = false;
    errorDetail : string = ""
    
    constructor(private translation: TranslateService, private inputService: InputService, private fb : FormBuilder) { 
    
    }
    
    ngOnInit(): void {   


        this.inputService.inputTags.subscribe((res:any) => {
                
            res = res.filter((element: any) => element.name == this.name )

            this.tags = res
            this.countTag = res.length

        })

                
        if(this.form.controls[this.name].value){     
            this.inputService.addTag( this.form.controls[this.name].value, this.name, this.label, this.spaceTag, this.arrayBoolean );     
        }
        
        this.placeholder && this.translation.get(this.placeholder).subscribe(res => this.placeholder = res);
        this.label && this.translation.get(this.label).subscribe(res => this.label = res);
    }

  
    onKeyUp(event: KeyboardEvent, ref? : any){         
        if (event.code === 'Backspace' || event.code === 'Enter' || event.code === 'Space'){
            return  this.inputService.onKeyUpInputTag(event, ref , this.tags, this.name,  this.label, this.form, this.spaceTag, this.arrayBoolean);   
        }
    }

    removeTag(tag : any){       

         this.inputService.removeTag(tag);
         this.onBlur();

    }
 
    inputClick(){
        this.myInput?.nativeElement.focus()

    }
  
    preventDefault(e : any){
        e.preventDefault()
    }
  


    onBlur(){
        this.value = [];
        
        if(this.form.controls['inputRef'].value){
            this.inputService.onKeyUpInputTag({code : 'Enter'}, this.form.controls['inputRef'] , this.tags, this.name,  this.label, this.form, this.spaceTag, this.arrayBoolean);   
        }

        this.tags?.map(t =>{ 
            this.value.push(t.tag)
        })
        
     

        this.name == 'NumRequisicao' || this.name == 'NumPedido' ?  this.form.controls[this.name].patchValue(this.value) :  this.form.controls[this.name].patchValue(this.value[0])
        
        this.form.controls['inputRef'].patchValue("");
    }

} 
