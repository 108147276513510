import { Action } from "@ngrx/store";
export const REQUEST = '[REQUEST] Request';
export const REQUEST_ERROR = '[REQUEST] Request error';
export const REQUEST_SUCCESS = '[REQUEST] Request success';

export const REQUEST_ALL = '[REQUEST GET ALL]  Request';
export const REQUEST_ALL_ERROR = '[REQUEST GET ALL] Request error';
export const REQUEST_ALL_SUCCESS = '[REQUEST GET ALL] Request success';

export const DOWNLOAD_FILE = '[DOWNLOAD] Download';
export const DOWNLOAD_SUCCESS = '[DOWNLOAD] Download success';
export const DOWNLOAD_ERROR = '[DOWNLOAD] Download error';



export class RequestGetAll implements Action {
    readonly type = REQUEST_ALL;

    constructor ( public request? : any ) {}
}

export class RequestGetAllError implements Action {
    readonly type = REQUEST_ALL_ERROR;

    constructor (public payload: any) {}
}

export class RequestGetAllSuccess implements Action {
    readonly type = REQUEST_ALL_SUCCESS;
    
    constructor ( public payload : any ) {}
}

export class Request implements Action {
    readonly type = REQUEST;
    
    constructor ( public request : any ) {}
}

export class RequestError implements Action {
    readonly type = REQUEST_ERROR;

    constructor (public request: any) {}
}

export class RequestSuccess implements Action {
    readonly type = REQUEST_SUCCESS;
    
    constructor ( public request : any ) {}
}

export class DownloadFile implements Action {

    readonly type = DOWNLOAD_FILE;
    
    constructor ( public request : any ) {}
}

export class DownloadSuccess implements Action {
    readonly type = DOWNLOAD_SUCCESS;
    
    constructor ( public payload : any ) {}
}

export class DownloadError implements Action {
    readonly type = DOWNLOAD_ERROR;
    
    constructor ( public payload : any ) {}
}


export type Actions = 
| Request
| RequestSuccess
| RequestError
| DownloadFile
| DownloadSuccess
| DownloadError



