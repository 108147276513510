import { Component, Input, OnInit, Sanitizer } from '@angular/core';
import { Dimensions } from '../dimensions.model';
import { IconsService } from '../icons.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() size: string = '40';
  @Input() class? : string ;
  @Input() classCheck? : string ;

  width: number = 40;
  height: number  = 40;


  constructor(private iconsService : IconsService) { }

  ngOnInit(): void {
    const dimensions : Dimensions = this.iconsService.setDimensions({size: this.size, width: this.width, height : this.height}) 
    this.width = dimensions.width
    this.height = dimensions.height
  }

}
