import { Component, Input, OnInit } from '@angular/core';
import { Dimensions } from '../dimensions.model';
import { IconsService } from '../icons.service';

@Component({
  selector: 'app-arrow-left',
  templateUrl: './arrow-left.component.html',
  styleUrls: ['./arrow-left.component.scss']
})
export class ArrowLeftComponent implements OnInit {
  @Input() size: string = '40';
  @Input() class? : string ;

  width: number = 40;
  height: number  = 40;

  constructor(private iconsService : IconsService) { }


  ngOnInit(): void{
    const dimensions : Dimensions = this.iconsService.setDimensions({size: this.size, width: this.width, height : this.height}) 
    this.width = dimensions.width
    this.height = dimensions.height
  }

}
