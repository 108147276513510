import { Component, Input, OnInit } from '@angular/core';
import { TrackingRequestsService } from '../../tracking-requests.service';

@Component({
  selector: 'app-quote-status',
  templateUrl: './quote-status.component.html',
  styleUrls: ['./quote-status.component.scss']
})
export class QuoteStatusComponent implements OnInit {
  @Input() item : any;
  @Input() order : any;

  verifyStatus : Boolean = true;
  quoteStatus = ['VERIFICANDO SOBRA DE ESTOQUE', 'NÃO INICIADO', 'EM COTAÇÃO', 'COTAÇÃO ENCERRADA', 'MATERIAL POSSUI CONTRATO']

  constructor(private trackingRequestsService : TrackingRequestsService) { }

  ngOnInit(): void {
    this.verifyStatus  = this.trackingRequestsService.verifyStatus(this.item.cotacoes?.status_cotacao, this.quoteStatus)

  }

}
