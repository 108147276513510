import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as shippingCompanyActions from "./shipping-company.actions";
import { map, mergeMap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import Swal from "sweetalert2";
import { ShippingCompanyService } from "@app/services/shipping-company.service";
import * as _ from "lodash";


@Injectable()
export class ShippingCompanyEffects {
    
  constructor(private actions$: Actions, private shippingCompanyService : ShippingCompanyService) {      

    
  }
  getAllShippingCompany$ = createEffect(() => 
  this.actions$
      .pipe (
          ofType<shippingCompanyActions.GetAllShippingCompany>(shippingCompanyActions.ALL_SHIPPING_COMPANY, shippingCompanyActions.ADD_SHIPPING_COMPANY_SUCCESS, shippingCompanyActions.REMOVE_SHIPPING_COMPANY_SUCCESS, shippingCompanyActions.REMOVE_ALL_SHIPPING_COMPANY_SUCCESS ),
          mergeMap(
             () => this.shippingCompanyService.getAllShippingCompany()
              .pipe(
                  map((res: any) => new shippingCompanyActions.GetAllShippingCompanySuccess(res.data)),
                  catchError((error: any) => of ( new shippingCompanyActions.GetAllShippingCompanyError(error) ))
              ),
          ),
    
      )
   )

   getShippingCompany$ = createEffect(() => 
   this.actions$
       .pipe (
           ofType<shippingCompanyActions.GetShippingCompany>(shippingCompanyActions.GET_SHIPPING_COMPANY ),
           mergeMap(
              data  => this.shippingCompanyService.getShippingCompany(data)
               .pipe(
                   map((res: any) => new shippingCompanyActions.GetAllShippingCompanySuccess(res.data)),
                   catchError((error: any) => of ( new shippingCompanyActions.GetAllShippingCompanyError(error) ))
               ),
           ),
     
       )
    )



  addShippingCompany$ = createEffect(() => 
  this.actions$
      .pipe (
          ofType<shippingCompanyActions.AddShippingCompany>( shippingCompanyActions.ADD_SHIPPING_COMPANY ),
          mergeMap(
             (data) => this.shippingCompanyService.addShippingCompany(data)
              .pipe(
                  map((res: any) => { 
                      Swal.fire(res.message,'', 'success')
                      return new shippingCompanyActions.AddShippingCompanySuccess(res.data)}),
                  
                  catchError((error: any) => { 
                    Swal.fire(error.error.message,'', 'error');
                    return of ( new shippingCompanyActions.AddShippingCompanyError(error));
                  })
            ),
          ),
      )
   )


   removeShippingCompany$ = createEffect(() => 
    this.actions$
        .pipe (
            ofType<shippingCompanyActions.RemoveShippingCompany>( shippingCompanyActions.REMOVE_SHIPPING_COMPANY ),
            mergeMap(
               (data) => this.shippingCompanyService.removeShippingCompany(data)
                .pipe(
                    map((res: any) => { 
                        Swal.fire(res.message,'', 'success')
                        return new shippingCompanyActions.RemoveShippingCompanySuccess(res)}),
                    
                    catchError((error: any) => { 
                      Swal.fire(error.error.message,'', 'error');
                      return of ( new shippingCompanyActions.RemoveShippingCompanyError(error));
                    })
                ),
            ),
        )
     )

    removeAllShippingCompany$ = createEffect(() => 
     this.actions$
         .pipe (
             ofType<shippingCompanyActions.RemoveAllShippingCompany>( shippingCompanyActions.REMOVE_ALL_SHIPPING_COMPANY ),
             mergeMap(
                () => this.shippingCompanyService.removeAllShippingCompany()
                 .pipe(
                    map((res: any) => { 
                        Swal.fire(res.message,'', 'success')
                        return new shippingCompanyActions.RemoveAllShippingCompanySuccess(res.data)}),
                    
                    catchError((error: any) => { 
                      Swal.fire(error.error.message,'', 'error');
                      return of ( new shippingCompanyActions.RemoveAllShippingCompanyError(error));
                    })
                 ),
             ),
         )
    )
  
  

}