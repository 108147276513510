<div  class="form-group form-group-mb-0 {{containerClass}}" [ngClass]="{'has-label': label}" [formGroup]="form">
    <label [attr.for]="id" class="{{labelClass}}" *ngIf="label">
        {{label}} {{required ? '*' : ''}}

        <span class="c-pointer" *ngIf="info" placement="bottom"  tooltipClass="custom-tooltip" [ngbTooltip]="tipContent">
            <app-info size="13" class="svg-stroke-secondary" ></app-info>
        </span>


        <ng-template #tipContent role="tooltip">
            <p>  {{tooltipText1}} </p>
            <p>  {{tooltipText2}} </p>        
            <p>  {{tooltipText3}} </p>
        </ng-template>


    </label>
    <span class="span-count">items: {{countTag}}</span>
    <div class="input-group c-pointer" (click)="inputClick()" [ngClass]="{'border-danger': hasError}">
      
        <span  class="tag-item ml-1 mt-2"  *ngFor="let tagInput of tags; let i = index;"   >
            
            {{ tagInput.tag }}
        
            <span class="remove-tag" (click)="removeTag(tagInput)" >                
                <app-close size="12" class="svg-stroke-light"></app-close>
            </span> 

        </span> 
       
      
        <input    
        [attr.maxlength]="maxLength"
        [attr.id]="id" 
        class="form-control {{inputClass}}"
        [attr.type]="type" 
        [attr.placeholder]="placeholder" 
        [attr.autocomplete]="autocomplete"
        [mask]="mask" 
        #ref 
        #myInput 
        formControlName="inputRef"
        (blur)="onBlur()"
        (keyup)="onKeyUp($event, ref)"
        [dropSpecialCharacters]="false"
        [attr.maxLength]="maxLength"
        (keydown)="pattern?.test($event.key) && $event.keyCode != 86 && $event.key != 'Backspace' ? preventDefault($event) : null"
        />

        <br>      
        <input type="hidden" [formControlName]="name"/>

       <div class="icon-input ml-2 mt-1" *ngIf="hasIcon">
             <ng-content></ng-content>
       </div>
       
    </div>

    <div class="invalid-feedback d-block" *ngIf="hasError" >
        {{ errorDetail }}
    </div>

</div>

