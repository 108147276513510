import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter} from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { switchMap } from 'rxjs/operators';
import { baseAPI } from '@app/constants';


@Injectable({
  providedIn: 'root'
})

export class EmailRequestService {
    itemsRequest: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor(private http: HttpClient, private authService : AuthService) {}

    createEmailRequest(data : any) : Observable<any> {
        
      return this.authService.authToken()
      .pipe(switchMap(auth => {
         
          const headers = new HttpHeaders({
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${auth.data.token}`
          });          

          
         return this.http.post(baseAPI + '/v1/requestEmail/createAll', data.request, {headers : headers})

      }))
    }



    getEmailRequest(data : any) : Observable<any> {
      
      return this.authService.authToken()
      .pipe(switchMap(auth => {
         
          const headers = new HttpHeaders({
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${auth.data.token}`
          });          
      
         return this.http.get(baseAPI + '/v1/requestEmail/get-by-email-notification',  {headers : headers, params: {email : data.email}})

      }))

    }

    updateNotification(data : any) : Observable<any> {
      
      return this.authService.authToken()
      .pipe(switchMap(auth => {
         
          const headers = new HttpHeaders({ 
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${auth.data.token}`
          });        
          
         return this.http.put(baseAPI + '/v1/requestEmail/disable-notification/'+data.email, '', { headers : headers } )

      }))


    }
 
}