import { Component, Input, OnInit } from '@angular/core';
import { TrackingRequestsService } from '../../tracking-requests.service';

@Component({
  selector: 'app-request-status',
  templateUrl: './request-status.component.html',
  styleUrls: ['./request-status.component.scss']
})
export class RequestStatusComponent implements OnInit {
  @Input() item : any;
  
  verifyStatusCoupa : Boolean = true;
  verifyStatus : Boolean = true;
  statusRc = ['AGUARDANDO LIBERAÇÃO', 'APROVADA', 'BLOQUEADA'];
  statusRcCoupa = ['RASCUNHO', 'AGUARDANDO LIBERAÇÃO', 'EM PROCESSAMENTO', 'PROCESSADA']
  constructor(private trackingRequestsService : TrackingRequestsService) { }

  ngOnInit(): void {
    if(this.item.tipo_processo == 'COUPA'){
      this.verifyStatusCoupa  = this.trackingRequestsService.verifyStatus(this.item.status_rc, this.statusRcCoupa)
    }else{
      this.verifyStatus = this.trackingRequestsService.verifyStatus(this.item.status_rc, this.statusRc)
    }

  }


}
