<div>
    
    <div class="row">

        <div class="col-xl-4 col-lg-4 col-md-4 ">
            <div class="row">
                <div *ngIf="order?.descricao_fornecedor || order?.descricao_fornecedor" class="col mb-2">
                    <b class="uppercase" translate>generic.providerName</b>
                    <br>
                    <span *ngIf="order.descricao_fornecedor">{{ order.descricao_fornecedor }}</span>
                </div>

                <div class="col mb-2">
                    <b class="uppercase" translate>generic.providerCod</b> 
                    <br>
                    <span *ngIf="order?.cod_fornecedor">{{order.cod_fornecedor}}</span>

                </div>
            </div>
        </div>
    
        <div class="col-xl-5 col-lg-5 col-md-6">
            <div class="row">
                <div class="col mt-lg-0 ">
                    <b class="uppercase" translate>generic.invoice</b>
                    <br>
                    <span *ngFor="let fatura of order?.faturas?.$values">{{fatura.num_fatura}}</span>
                </div>

                <div class="col mt-lg-0">
                    <b class="uppercase" translate>request.contract</b>
                    <br>
                    
                    <span *ngIf="item.cod_contrato_req">{{item.cod_contrato_req}}</span>
                    <span *ngIf="!item.cod_contrato_req && order?.cod_contrato_pedido">{{order?.cod_contrato_pedido}}</span>
                    <span *ngIf="!item.cod_contrato_req && !order?.cod_contrato_pedido &&   item.cotacoes?.cod_contrato">{{  item.cotacoes?.cod_contrato }}</span>

                  
                </div>

                <div class="col  mt-lg-0">
                
                    <b class="uppercase" translate>generic.blueprint</b>
                
                    <p>
                        <span *ngIf="item.cod_centro_rc">{{item.cod_centro_rc}}</span>
                        <span *ngIf="!item.cod_centro_rc && order.cod_centro_pedido">{{order.cod_centro_pedido}}</span>
                    </p>
                </div>

                <div class="col mt-lg-0">
                    <b class="uppercase" translate>request.orderCreate</b>
                    <br>
                    <span *ngIf="order?.grupo_compra_pedido">{{order?.grupo_compra_pedido}}</span>
                </div>
            </div>
        </div> 

    </div>

   
    <div class="row mb-5">
        <div class="col-lg-12">
            <div class="request__info__details pl-5 pr-5 pt-4">
                <div class="d-flex">
                   
                    <!-- request -->
                    <div  class="request__status mt-2 mb-5  mr-n4" >
                        <app-request-flow [item]="item" [order]="order"> </app-request-flow>                        
                    </div>

                    <!-- quotation -->
                    <!-- *ngIf="item.cotacoes?.num_cotacao || item.grupo_compra_rc == 'INV' || item.cod_contrato_req" -->
                    <!-- item.tipo_processo == 'COUPA' && item.tipo_processo != 'CATÁLOGO'  && item.tipo_processo  -->
                    <div  class="request__status mt-2 mb-5  mr-n4" >
                        <app-quote-flow [item]="item" [order]="order"> </app-quote-flow>
                    </div>

                     <!-- order -->
                    <div  class="request__status  pt-2  mb-5 ml-4 " >
                        <app-order-flow [order]="order" [item]="item" [index]="index" [items]="items" [traffic]="traffic"></app-order-flow>
                    </div>

                    <!-- traffic  -->
                    <span>
                        <app-traffic-flow [traffic]="traffic" [item]="item"  [order]="order"></app-traffic-flow>
                    </span>

                    <!-- receivement -->
                    <span>
                        <app-receiving-flow [item]="item" [traffic]="traffic" [order]="order"> </app-receiving-flow>
                    </span>


                </div>
            </div>
        </div>
    </div>
</div>