<div class="row">
    <div class="col-auto">
        <h2 mat-dialog-title translate>modal.filterAdm</h2>
    </div>
    <div class="col">
        <button class="btn btn-sm btn-outline-info float-right" mat-dialog-close >
        <app-close class="svg-stroke-secondary" size="20"></app-close>
        </button>
    </div>
</div>
  
<mat-dialog-content  class="modalText dialog-content">
    <div class="row">
       
        <div class="col-auto">
            <span class="c-pointer"  [ngClass]="{'activeType': formAdd.value.tipo_categoria == 1}"  (click)="formAdd.reset();  formAdd.controls.tipo_categoria.setValue(1); getList(1); shippingCompany = false;" translate>generic.center</span>
            <hr *ngIf="formAdd.value.tipo_categoria == 1" class="activeUnderline" >    
        </div>
    
        <div class="col-auto">
            <span class="c-pointer" [ngClass]="{'activeType': formAdd.value.tipo_categoria == 2}" (click)=" formAdd.reset(); formAdd.controls.tipo_categoria.setValue(2); getList(2); shippingCompany = false;"  translate>generic.typeOrder</span>
            <hr *ngIf="formAdd.value.tipo_categoria == 2" class="activeUnderline" >    
        </div>

        <div class="col-auto">
            <span class="c-pointer"  [ngClass]="{'activeType': formAdd.value.tipo_categoria == 3}"  (click)="formAdd.reset(); formAdd.controls.tipo_categoria.setValue(3); getList(3); shippingCompany = false; " translate>generic.typeRequest</span>
            <hr *ngIf="formAdd.value.tipo_categoria == 3" class="activeUnderline" >    
        </div>
        <div class="col-auto" >
            <span class="c-pointer" [ngClass]="{'activeType': shippingCompany}"  (click)="shippingCompany = true; formAdd.reset(); getShippingCompany();" translate>generic.shippingCompany</span>
            <hr *ngIf="shippingCompany" class="activeUnderline" >    
        </div>
    
    </div>

    <form *ngIf="shippingCompany" [formGroup]="formCompany" class="mt-3 text-left">

        <app-input type="number" [hasIcon]="true" inputClass="border-0" placeholder="{{ 'modal.enterCodShippingCompany' | translate}}" labelClass="font-weight-bold" label="{{ 'modal.codShippingCompany' | translate}}" name="codTR" required="true" [form]="formCompany"> 
            <span class="c-pointer" (click)="formCompany.controls.codTR.setValue('')" >
                <app-close  size="22" class="svg-stroke-secondary"></app-close>
            </span> 
        </app-input>

       <app-input  [hasIcon]="true" inputClass="border-0" placeholder="{{ 'modal.enterLinkShippingCompany' | translate}}" labelClass="font-weight-bold" label="{{ 'modal.linkShippingCompany' | translate}}" name="siteTR" required="true" [form]="formCompany"> 
            <span class="c-pointer" (click)="formCompany.controls.siteTR.setValue('')" >
                <app-close  size="22" class="svg-stroke-secondary"></app-close>
            </span> 
        </app-input>

        <div class="col-12">
            <button type="button" (click)="addListShipping()" class="btn btn-primary float-right mr-n3  mt-1 mb-3" translate>
                generic.add
            </button>
        </div>

    </form>

    <form *ngIf="!shippingCompany" [formGroup]="formAdd"  class="mt-3 ml-n3 mr-n3 text-left">
        <div class="col-12">
            <span *ngIf="formAdd.value.tipo_categoria == 1">            
                 <app-input  [hasIcon]="true" inputClass="border-0" placeholder="{{ 'modal.EnterCodCenter' | translate}}" labelClass="font-weight-bold" label="{{ 'modal.codCenter' | translate}}" name="cod_categoria" required="true" [form]="formAdd"> 
                     <span class="c-pointer" (click)="formAdd.controls.cod_categoria.setValue('')" >
                         <app-close  size="22" class="svg-stroke-secondary"></app-close>
                     </span> 
                </app-input>
            </span>

            <span *ngIf="formAdd.value.tipo_categoria == 2">            
                <app-input [hasIcon]="true" inputClass="border-0" placeholder="{{ 'modal.EnterCodOrder' | translate}}" labelClass="font-weight-bold" label="{{ 'modal.codOrder' | translate}}" name="cod_categoria" required="true" [form]="formAdd"  [maxLength]="4" >
                    <span class="c-pointer" (click)="formAdd.controls.cod_categoria.setValue('')" >
                        <app-close  size="22" class="svg-stroke-secondary"></app-close>
                    </span> 
                </app-input>
           </span>

           <span *ngIf="formAdd.value.tipo_categoria == 3">            
                <app-input  [hasIcon]="true" inputClass="border-0" placeholder="{{ 'modal.EnterCodRequest' | translate}}" labelClass="font-weight-bold" label="{{ 'modal.codRequest' | translate}}" name="cod_categoria" required="true" [form]="formAdd" >
                    <span class="c-pointer" (click)="formAdd.controls.cod_categoria.setValue('')" >
                        <app-close  size="22" class="svg-stroke-secondary"></app-close>
                    </span> 
                </app-input>
           </span>
        </div>

        <div class="col-12">
            <span *ngIf="formAdd.value.tipo_categoria == 1">            
                <app-input [hasIcon]="true"  id="description" placeholder="{{ 'modal.EnterDescriptionCenter' | translate}}" labelClass="font-weight-bold" label="{{ 'modal.descriptionCenter' | translate}}" name="des_categoria" [form]="formAdd" > 
                    <span class="c-pointer" (click)="formAdd.controls.des_categoria.setValue('')" >
                        <app-close  size="22" class="svg-stroke-secondary"></app-close>
                    </span> 
                 </app-input>
            </span>

            <span *ngIf="formAdd.value.tipo_categoria == 2">            
                <app-input [hasIcon]="true"  id="description" placeholder="{{ 'modal.EnterDescriptionOrder' | translate}}" labelClass="font-weight-bold" label="{{ 'modal.descriptionOrder' | translate}}" name="des_categoria" [form]="formAdd" > 
                    <span class="c-pointer" (click)="formAdd.controls.des_categoria.setValue('')" >
                        <app-close  size="22" class="svg-stroke-secondary"></app-close>
                    </span> 
                </app-input>
            </span>

            <span *ngIf="formAdd.value.tipo_categoria == 3">            
                <app-input [hasIcon]="true"  id="description" placeholder="{{ 'modal.EnterDescriptionRequest' | translate}}" labelClass="font-weight-bold" label="{{ 'modal.descriptionRequest' | translate}}" name="des_categoria" [form]="formAdd" > 
                    <span class="c-pointer" (click)="formAdd.controls.des_categoria.setValue('')" >
                        <app-close  size="22" class="svg-stroke-secondary"></app-close>
                    </span> 
                </app-input>
            </span>
        </div>

        <div class="col-12">
            <button type="button" (click)="addList()" class="btn btn-primary float-right ml-3 mt-1 mb-3" translate>
                generic.add
            </button>
        </div>
    </form>

    <table  class="table table-management overflow mt-4 ">
        <thead class="table-management__thead uppercase text-left">
          <tr>
            <th scope="col" translate>generic.cod</th>
            <th scope="col" translate>generic.description</th>
          </tr>
        </thead>
        
        <tbody  class="text-left" *ngFor="let s of shippingCompanyList" >
            <tr *ngIf="shippingCompany">
                <td> {{s.codTR}}</td>
                <td> {{s.siteTR}} <span class="float-right c-pointer" (click)="removeShippingCompanyList(s.codTR, false)"><app-close size="22" class="svg-stroke-secondary"></app-close></span></td>
            </tr>
        </tbody>

        <tbody *ngIf="!shippingCompany" class="text-left"  >
            <tr *ngFor="let f of filterList">
                <td> {{f.cod_categoria}}</td>
                <td> {{f.des_categoria}} <span class="float-right c-pointer" (click)="removeList(f)"><app-close size="22" class="svg-stroke-secondary"></app-close></span></td>
            </tr>
        </tbody>

    </table>

</mat-dialog-content> 
  
  
<mat-dialog-actions class="float-left">
    <button mat-button  class="btn btn-outline-info btn-sm btn-font font-weight-bold mr-3" (click)="shippingCompany ? removeShippingCompanyList('', true)  : removeList('', true)" translate>modal.cleanAll</button>
</mat-dialog-actions>
  
  