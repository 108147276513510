import { NgModule } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '@app/app-routing.module';
import { IconsModule } from './icons/icons.module';
import { HeaderComponent } from './header/header.component';
import { CustomFormModule } from './form/form.module';
import { HttpLoaderFactory } from '@app/translator';
import { FooterComponent } from './footer/footer.component';
import { SearchMenuComponent } from './search-menu/search-menu.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatChipsModule } from '@angular/material/chips';
import { ModalEmailComponent } from './modal-email/modal-email.component';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ModalManagementComponent } from './modal-management/modal-management.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SearchMenuComponent,
    ModalEmailComponent,
    ModalManagementComponent
  ],

  imports: [
    AppRoutingModule,
    CommonModule,
    FormsModule,
    IconsModule,
    CustomFormModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxPaginationModule,
    MatChipsModule,
    MatDialogModule
    ],

  exports: [
    HeaderComponent,
    FooterComponent,
    IconsModule,
    SearchMenuComponent,
    CustomFormModule,
    ModalEmailComponent,
    ModalManagementComponent
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ],
})

export class ComponentsModule { }