import { AccessManagementModel } from "@app/model/access-management";
import * as accessManagement from "./access-management.actions";



export function AccessManagementReducer (  state : AccessManagementModel[] = [], action: any) {

    switch (action.type) {
     
        case accessManagement.ACCESS_FILTERS_SUCCESS: 
            return action.payload
               
        default:
            return state




    }
}


