import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss']
})
export class RequestDetailsComponent implements OnInit {
  @Input('item') item: any = []
  @Input('items') items: any = []
  @Input('order') order?: any = []
  @Input('index') index? : any;
  traffic  : any = [];

  constructor(private store: Store<any>) { }
  ngOnInit(): void {

    if(this.order?.faturas){

      this.order?.faturas.$values.map((res : any, i : number) => {
        
        if(this.traffic.length === 0){
                    
          this.traffic = res;

        }
      
      })

    }

  }

 

}
