import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AppState } from '@app/app.state';
import { urlLogo } from "@constants";
import { imagePath } from "@constants";
import { Store } from '@ngrx/store';
import * as actionEmail from "@store/email/email.actions";
import { Observable } from 'rxjs';
import { ModalManagementComponent } from '../modal-management/modal-management.component';
import { MicrosoftService } from '@app/services/microsoft.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  urlLogo: string = urlLogo;
  dropUser: boolean = false;
  dropNotify: boolean = false;
  imagePath : string = imagePath;
  notify : boolean = false
  email : any
  manageFilters : boolean  = false
  readonly requestNotifyItems$: Observable<any> = this.store.select('email');

  constructor(public dialog: MatDialog, private store: Store<any>, private microsoft : MicrosoftService
    ) { }

  ngOnInit(): void {   

    if(localStorage.getItem('email') != undefined){   
      this.email = localStorage.getItem('email')?.toString()      
    }

    if(localStorage.getItem('groups') != undefined) {
      var groups = JSON.parse(JSON.stringify(localStorage.getItem('groups')))      
      if( groups.includes('c634c4d8-048e-4c8f-bfee-900a40080ef1') || groups.includes('Adm')){
        this.manageFilters = true
      }   
    }

    

    this.getNotification()


    this.requestNotifyItems$.subscribe((res) => {       
      if( res.notification){
        res.notification.notificacao ? this.notify = true  : this.notify = false
      }
    });

  }


  getNotification(){ 
     this.store.dispatch( new actionEmail.getEmailNotification(this.email));
  }

  updateNotification(){
    this.store.dispatch( new actionEmail.updateNotification(this.email));
  }

  openDialog() {
    const dialogRef = this.dialog.open(ModalManagementComponent, { data : {items : 3}});

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  logout() { 
    this.microsoft.logoff();
  }


}