import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './logo/logo.component';
import { CalendarComponent } from './calendar/calendar.component';
import { FollowComponent } from './follow/follow.component';
import { ArrowDownComponent } from './arrow-down/arrow-down.component';
import { ArrowUpComponent } from './arrow-up/arrow-up.component';
import { UserComponent } from './user/user.component';
import { BellComponent } from './bell/bell.component';
import { SearchComponent } from './search/search.component';
import { FilterComponent } from './filter/filter.component';
import { CloseComponent } from './close/close.component';
import { SuccessComponent } from './success/success.component';
import { ProgressComponent } from './progress/progress.component';
import { AlertComponent } from './alert/alert.component';
import { InterrogationComponent } from './interrogation/interrogation.component';
import { InfoComponent } from './info/info.component';
import { ClockComponent } from './clock/clock.component';
import { DownloadComponent } from './download/download.component';
import { ProgressLineComponent } from './progress-line/progress-line.component';
import { VerticalLineComponent } from './vertical-line/vertical-line.component';
import { CircleComponent } from './circle/circle.component';
import { ArrowLeftComponent } from './arrow-left/arrow-left.component';
import { ArrowRightComponent } from './arrow-right/arrow-right.component';
import { EmailComponent } from './email/email.component';
import { ExternalLinkComponent } from './external-link/external-link.component';
import { LoadingComponent } from './loading/loading.component';

@NgModule({
  declarations: [LogoComponent,CalendarComponent, FollowComponent, ArrowDownComponent, ArrowUpComponent, UserComponent, BellComponent, SearchComponent, FilterComponent, CloseComponent, SuccessComponent, ProgressComponent, AlertComponent, InterrogationComponent, InfoComponent, ClockComponent, DownloadComponent, ProgressLineComponent, VerticalLineComponent, CircleComponent, ArrowLeftComponent, ArrowRightComponent, EmailComponent, ExternalLinkComponent, LoadingComponent],
  imports: [
    CommonModule
  ],
  exports: [LogoComponent,CalendarComponent, FollowComponent, ArrowDownComponent, ArrowUpComponent, UserComponent, BellComponent, SearchComponent, FilterComponent, CloseComponent, SuccessComponent, ProgressComponent, AlertComponent, InterrogationComponent, InfoComponent, ClockComponent, DownloadComponent, ProgressLineComponent, VerticalLineComponent, CircleComponent, ArrowLeftComponent, ArrowRightComponent, EmailComponent, ExternalLinkComponent, LoadingComponent] 
})

export class IconsModule { }
