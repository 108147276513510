import * as requestActions from "./request.actions";

export function RequestReducer (state: any, action: any) {
    
    switch (action.type) {

        case requestActions.REQUEST_SUCCESS:
            
            return {message: action.request.message, data: action.request.data.$values, error:false};

        case requestActions.REQUEST_ERROR:     

            return {error : true, message: action.request.error.message, data:false};


        // case requestActions.DOWNLOAD_FILE:   

        //     return action.payload

        // case requestActions.DOWNLOAD_SUCCESS :         

        //      return {error : false, messageDownload: action, data:false};

        //  case requestActions.DOWNLOAD_ERROR :       
                        
        //     return {error : true, messageDownload: action, data:false};


        default :
            return state
    }
}