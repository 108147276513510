import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-receiving-flow',
  templateUrl: './receiving-flow.component.html',
  styleUrls: ['./receiving-flow.component.scss']
})
export class ReceivingFlowComponent implements OnInit {
  @Input('traffic') traffic?: any
  @Input('item') item: any
  @Input('order') order?: any

  constructor() { }

  ngOnInit(): void {

  }

}                                                    
