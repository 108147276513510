<div class="row pt-3 ml-3">

    <div class="col-1 mr-lg-3 mr-3 ml-lg-1">
        <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
    </div>

       
    <div class="col-8 request__status font-14">
        <div  *ngIf="(item.faturas.$values[0].status_recebimento | uppercase) == 'MATERIAL RECEBIDO' || item.faturas.$values[0].dt_entrada_fisica">
            <b class="request__status__success uppercase" translate> request.materialReceived </b>

            <span>{{ item.faturas.$values[0].dt_entrada_fisica | date: 'dd/MM/yyyy' }}</span>    
        </div>

        <div *ngIf="(item.faturas.$values[0].status_recebimento | uppercase) == 'NÃO POSSUI ENTRADA FÍSICA' || !item.entrada_fisica ">
            <b class="request__status__success uppercase" translate> generic.receivement  </b>
            <br>
            <span class="lowercase">{{ item.faturas.$values[0].status_recebimento }}</span>

        </div>
    </div>

</div>