import { Component, Input, OnInit } from '@angular/core';
import { Dimensions } from '../dimensions.model';
import { IconsService } from '../icons.service';

@Component({
  selector: 'app-progress-line',
  templateUrl: './progress-line.component.html',
  styleUrls: ['./progress-line.component.scss']
})
export class ProgressLineComponent implements OnInit {
  @Input() size?: string ;
  @Input() class? : string ;


  width: any = '100%';
  height: any  = '100%';

  constructor(private iconsService : IconsService) { }
  
  ngOnInit(): void {    
  
    if(this.size){

      const dimensions : Dimensions = this.iconsService.setDimensions({size: this.size, width: this.width, height : this.height}) 
      
      this.width = dimensions.width;    
      this.height = dimensions.height;

    }
   
  }

}
