<div class="request__status mt-2 mb-5  mr-n4" >
                         
    <b class="request__status__title font-weight-bold mt-3" translate> generic.receivement </b>   
    
    <div class="row mt-1">
        <span class="col-1 pt-2 ">
         
            <div *ngIf="traffic">
                <div *ngIf="(traffic.status_recebimento | uppercase ) == 'NÃO POSSUI ENTRADA FÍSICA' || (traffic.status_recebimento | uppercase) == 'MATERIAL RECEBIDO' ||  traffic.dt_entrada_fisica || !order?.entrada_fisica && (item.status_rc | uppercase) != 'BLOQUEADA' && !item.dt_bloqueio_rc" >
                    <span class="position-absolute mt-3 ml-3">1/1</span>
                    <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
                </div>
            </div>
          
            <div *ngIf="(!traffic || traffic.length == 0) && order?.entrada_fisica|| (!traffic.status_recebimento && order?.entrada_fisica && !traffic.dt_entrada_fisica)  "> 
                <span class="position-absolute mt-3 ml-3">0/1</span>
                 <app-progress size="50" class1="svg-default-green" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
            </div>


    
        </span>
    </div> 

    <div *ngIf="(traffic.status_recebimento | uppercase) == 'MATERIAL RECEBIDO' || traffic?.dt_entrada_fisica || (traffic?.status_recebimento | uppercase) == 'NÃO POSSUI ENTRADA FÍSICA'  || !order?.entrada_fisica  && (item.status_rc | uppercase) != 'BLOQUEADA' && !item.dt_bloqueio_rc">
        <div class="row">
            <div class="col">
                <app-vertical-line size="55" class="svg-success"></app-vertical-line>
            </div>
        </div>
    
        <div class="d-flex">
            <div class="pl-4 mt-n2" >
                <app-circle size="8" class="svg-success"></app-circle>
            </div>
    
            <div class="request__status__textCurrent ml-2">    
                <div *ngIf="(traffic.status_recebimento | uppercase ) == 'NÃO POSSUI ENTRADA FÍSICA' || !order?.entrada_fisica ">
                    <span class="font-18" translate>request.notPhysicalInput</span>
                </div>
    
                <div *ngIf="(traffic.status_recebimento | uppercase) == 'MATERIAL RECEBIDO' ||  traffic?.dt_entrada_fisica  ">
                    <span class="font-18" translate>request.materialReceived</span>
                    <br>
                    <div class="font-14">
                        <span translate>request.physicalEntryDate</span> : 
                        <br>
                        <span>{{traffic?.dt_entrada_fisica | date:  'dd/MM/yyyy'}}</span>
                        <br>
                        <span translate>request.amountReceived</span>
                        <br>
                        <span>{{traffic?.quant_entrada_fisica}} </span> {{order?.unidade_pedido}}
                    </div>
                  
                </div>
            </div>
            
        </div>
    </div>
    



</div>