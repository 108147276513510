import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseAPI } from '@app/constants';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TrackingRequestsService {

  constructor() {     
  }

  

  verifyStatus(currentStatus : string, arrayStatus : any[] ) : Boolean{
  
    if(currentStatus){
      currentStatus = currentStatus.toUpperCase()
    }
    return arrayStatus.includes(currentStatus)
  }

  
}
