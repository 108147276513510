<body>
    <div class="row">

        <div class="col-auto mt-n2" *ngIf="sidebarSearch">
            <app-search-menu  [formSearch]="formSearch"  [arrayTags]="arrayTags" (updateVisible)="sidebarSearch = $event" (formDataValue)="cleanForm($event)" [(visible)]="sidebarSearch"></app-search-menu>
        </div>

        <div class="col">
            <section class="ml-xl-5 mr-xl-5 ml-lg-3 mt-5 pt-4 mr-lg-3 ml-4 mr-4 ">

                <div class="row mt-3 pb-2">
                    <div class="col-12">
                        <span class="title" translate>home.listOfRequest</span>
                    </div> 
                </div>

                <div class="row mb-1">
                    
                    <div class="col-lg col-xl-9 col "  >
                        <button class="btn-sm btn-gray font-weight-bold" (click)="sidebarSearch = true">
                            <app-search size="20" class="svg-stroke-dark"></app-search>
                            <span translate> search.searchRequests</span>
                        </button>  
                
                        <!-- <span  [ngClass]="{'d-none': i > 5 && !plus}" *ngFor="let inputTag of arrayTags;let i = index; let first = first; let last = last">
                                            
                            <div class="tag-item font-12 p-1 ml-1 mt-2" >
                                <span>
                                    <b translate>{{inputTag.label}}</b>: {{inputTag.tag}}
                                </span>
                                
                                <span class="remove-tag" (click)="removeTag(inputTag)" >
                                    <app-close size="12" class="svg-stroke-light"></app-close>
                                </span>
                            </div>
                            
                            <span class="btn btn-sm btn-plus btn-outline-info ml-3" (click)="plus = true" *ngIf="arrayTags.length > 6 && i == 5 && !plus"> + {{ arrayTags.length - 6}}</span> 
                            <span class="btn btn-sm btn-plus btn-outline-info ml-3" (click)="plus = false" *ngIf="plus && last" translate> home.seeLess</span> 
                        </span>  -->
                                        
                        <button [disabled]="items.length == 0" [ngClass]="{'d-block': arrayTags.length > 0 }"  class="d-lg-none d-none btn btn-outline-info btn-font float-right mt-4"  (click)="exportAsExcelFile()">
                            <app-download size="16" class="svg-stroke-secondary"></app-download>
                            <span translate class="mt-1 font-weight-bold"> request.downloadXLS </span>    
                        </button> 
                        
                    </div>

                    <div  [ngClass]="{'d-none' : arrayTags.length > 0 }" class="col-lg col d-lg-inline" >
                        <button [disabled]="items.length == 0" class="btn btn-outline-info btn-font float-right"  (click)="exportAsExcelFile()">
                            <app-download size="16" class="svg-stroke-secondary"></app-download>
                            <span translate class="mt-1 font-weight-bold"> request.downloadXLS </span>    
                        </button> 
                    </div>

                </div>  
             
                                
                <div class="row mb-2 mt-2">
                    <div class="col numberItems font-12 uppercase font-bold">
                        <span translate>request.numberItems</span> : 
                        <span>{{items.length}}</span>

                    </div>
                </div>
                             
                <app-tracking-requests [items]="items" [itemsPerPage]="itemsPerPage" [currentPage]="currentPage"></app-tracking-requests>    
           
            </section>
        </div>

     
    </div>
    
    <app-footer [items]="items"  [sidebar]="sidebarSearch" [itemsLength]="items.length" (itemsPerPage)="itemsPerPage = $event"  (currentPage)="currentPage = $event"></app-footer>

</body>               
