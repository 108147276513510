import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { Option } from '../form/model/radio.models';
import { InputService } from '../form/input.service';
import { mask_cnpj, regex_string } from '@app/components/form/masks';
import { regex_number_and_dash , regex_number} from '@app/components/form/masks';
import { Store } from '@ngrx/store';
import * as actionRequest from "@store/request/request.actions";
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-search-menu',
  templateUrl: './search-menu.component.html',
  styleUrls: ['./search-menu.component.scss']
})
export class SearchMenuComponent implements OnInit {
  @ViewChild('resetClick') 'resetClick': ElementRef;
  @Input() visible? : boolean = false;
  @Output() updateVisible = new EventEmitter();
  @Input() arrayTags? : any
  @Output() formDataValue = new EventEmitter();
  tags :any
  mask_cnpj : string = mask_cnpj;
  regex_number_and_dash : RegExp = regex_number_and_dash
  regex_string : RegExp = regex_string
  regex_number: RegExp = regex_number
  data : any = {};
  readonly requestItems$: Observable<any> = this.store.select('request');

  @Input() formSearch! : FormGroup;

  today : string =  moment(new Date).format('DD/MM/YYYY')
  typeRequest : Option[] = [
    {code: '10', value: 'teste'},
    {code: '15', value: 'teste2'},
    {code: '20', value: 'teste3'},
  ];
    
  finalShipmentOptions : Option[] = [];
  deletedOrdersOptions : Option[] = [];

  constructor(protected translation: TranslateService, private inputService: InputService,private store: Store<any>, private formBuilder : FormBuilder) { 


  }

  ngOnInit(): void {   
    
    this.translation.get('generic.yes').subscribe(res => {

      this.finalShipmentOptions.push({code:'S', value : res}); 
      this.deletedOrdersOptions.push({code:'S', value : res}); 

    });

    this.translation.get('generic.no').subscribe(res => {

      this.finalShipmentOptions.push({code:'N', value : res}); 
      this.deletedOrdersOptions.push({code:'N', value : res}); 
      
    });

    this.translation.get('generic.all').subscribe(res => {

      this.finalShipmentOptions.push({code:'T', value : res}); 
      this.deletedOrdersOptions.push({code:'T', value : res}); 
      
    });

  }

  onSubmit(){
    
    this.formFormat()
    
    
    Object.keys(this.formSearch.value).filter(a => this.formSearch.value[a] !== null && this.formSearch.value[a] != '').forEach(key => {     
      if(key != "DataPedidoInicial" && key != "DataPedidoFinal" && key != "CodNomeFornecedor" && key != "CodNomeMaterial"  && key != "CNPJ" && this.formSearch.value[key] != undefined && this.formSearch.value[key] !=  ""){
        this.data[key] = this.formSearch.value[key];      
      }
    });

  
    this.formDataValue.emit(0)


    this.store.dispatch(new actionRequest.Request(this.data));

    this.close();
  }
  
  formFormat(){

    if(this.formSearch.controls.CNPJ.value){
      this.data.CNPJ =  this.formSearch.controls.CNPJ.value.replace(/[^\w\s]/gi, '')
    }
    
    if(this.formSearch.controls.DataPedidoInicial.value) {
      this.data.DataPedidoInicial = formatDate(new Date(this.formSearch.controls.DataPedidoInicial.value), 'yyyy-MM-dd', 'en')
    }

    if(this.formSearch.controls.DataPedidoFinal.value ){
      this.data.DataPedidoFinal = formatDate(new Date(this.formSearch.controls.DataPedidoFinal.value), 'yyyy-MM-dd', 'en')
    }
 

    if( this.formSearch.value.CodNomeMaterial ){
      isNaN(this.formSearch.value.CodNomeMaterial.replace('-','')) ? this.data.NomeMaterial = this.formSearch.value.CodNomeMaterial :  this.data.CodMaterial = this.formSearch.value.CodNomeMaterial.replace(/^(0+)(\d)/g,"$2");

    }

    if( this.formSearch.value.CodNomeFornecedor ){

      isNaN(this.formSearch.value.CodNomeFornecedor.replace('-','')) ? this.data.NomeFornecedor  = this.formSearch.value.CodNomeFornecedor :  this.data.CodFornecedor = this.formSearch.value.CodNomeFornecedor.replace(/^(0+)(\d)/g,"$2");

    }

  
  }
  close(){
    this.updateVisible.emit(false);
  }

  reset(){
    this.resetClick.nativeElement.click();

    this.formDataValue.emit(1)

    this.inputService.removeTag("all")
  }

 

}
