import { Component, Input, OnInit } from '@angular/core';
import { Dimensions } from '../dimensions.model';
import { IconsService } from '../icons.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input() size: string = '40';

  width: number = 40;
  height: number  = 40;
  constructor(private iconsService : IconsService) { }

  ngOnInit(): void {
    const dimensions : Dimensions = this.iconsService.setDimensions({size: this.size, width: this.width, height : this.height}) 
    this.width = dimensions.width
    this.height = dimensions.height
  }

}
