import { Component, Input, OnInit } from '@angular/core';
import { TrackingRequestsService } from '../../tracking-requests.service';

@Component({
  selector: 'app-request-flow',
  templateUrl: './request-flow.component.html',
  styleUrls: ['./request-flow.component.scss']
})
export class RequestFlowComponent implements OnInit {
  @Input('item') item: any
  @Input('order') order?: any
  verifyStatusCoupa : Boolean = true;
  verifyStatus : Boolean = true;
  statusRcCoupa = ['RASCUNHO','AGUARDANDO LIBERAÇÃO', 'EM PROCESSAMENTO', 'PROCESSADA'];
  statusRc = ['AGUARDANDO LIBERAÇÃO', 'APROVADA', 'BLOQUEADA'];
  constructor(private trackingRequestsService : TrackingRequestsService) { }

  ngOnInit(): void {
    if(this.item.tipo_processo == 'COUPA'){
      this.verifyStatusCoupa  = this.trackingRequestsService.verifyStatus(this.item.status_rc, this.statusRcCoupa)
    }else{
      this.verifyStatus = this.trackingRequestsService.verifyStatus(this.item.status_rc, this.statusRc)
    } 

  }


}
