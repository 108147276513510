import { Component, Input, OnInit } from '@angular/core';
import { ShippingCompanyModel } from '@app/model/shipping-company';
import { Store } from '@ngrx/store';
import * as shippingCompany from "@store/shipping-company/shipping-company.actions";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-traffic-flow',
  templateUrl: './traffic-flow.component.html',
  styleUrls: ['./traffic-flow.component.scss']
})
export class TrafficFlowComponent implements OnInit {
  @Input('traffic') traffic?: any
  @Input('item') item: any
  @Input('order') order?: any

  readonly shippingCompanyList$: Observable<ShippingCompanyModel[]> = this.store.select('shippingCompany');

  constructor(private store: Store<any>) { }

  ngOnInit(): void {
    
  }

  linkTransport(codTr : number){
    this.store.dispatch( new shippingCompany.GetShippingCompany(codTr))

    this.shippingCompanyList$.subscribe((res :any) => {  
      if(res.siteTR){
         window.open("http://"+res.siteTR);

      }
    }) 
  }
}
