
<div class="request__status mt-2 mb-5  mr-n4" >
        
    <b class="request__status__title font-weight-bold mt-3" translate> generic.traffic</b>

    <div class="row mt-1">
        <span class="col-1 pt-2 ">
  
            <div *ngIf="(!traffic && order?.entrada_fisica) || (!order?.faturas?.$values[0]?.num_fatura || order?.faturas?.$values[0]?.num_fatura == 0)  && order?.iconterms_pedido != 'CIF' ">
                <span class="position-absolute mt-3 ml-3">0/3</span>
                <app-progress size="50" class1="svg-default-green" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
            </div>
            <div *ngIf="order?.iconterms_pedido == 'CIF' ">
                <span class="position-absolute mt-3 ml-3">3/3</span>
                <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
            </div>
            
            <div *ngIf="traffic && order?.faturas?.$values[0]?.num_fatura">
                
                <div *ngIf="order?.iconterms_pedido != 'CIF'">
                    <div *ngIf="!traffic.dt_previsao_coleta && !traffic.status_fatura && order?.entrada_fisica && !traffic.dt_entrada_fisica">
                        <span class="position-absolute mt-3 ml-3">0/3</span>
                        <app-progress size="50" class1="svg-default-green" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
                    </div>
    
                    <div *ngIf="(traffic.status_fatura | uppercase) == 'AGUARDANDO COLETA' && !traffic.dt_entrada_fisica && order?.entrada_fisica">
                        <span class="position-absolute mt-3 ml-3">1/3</span>
                        <app-progress size="50" class1="svg-success" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
                    </div>                        
    
                    <div *ngIf="(traffic.status_fatura | uppercase) == 'EM TRÂNSITO'  && (traffic.status_fatura | uppercase) != 'ENTREGUE' && !traffic.dt_entrada_fisica && order?.entrada_fisica" >
                        <span class="position-absolute mt-3 ml-3">2/3</span>
                        <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-default-green"></app-progress>
                    </div>
                        
                    <div *ngIf="(traffic.status_fatura | uppercase) == 'ENTREGUE' || !order?.entrada_fisica  || traffic.dt_entrada_fisica ">
                        <span class="position-absolute mt-3 ml-3">3/3</span>
                        <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
                    </div>
                    
                </div>
              
                
            </div>
            



        </span>

        <span class="col mt-4 ml-3" *ngIf="(!traffic.dt_chegada && (traffic.status_fatura | uppercase) != 'ENTREGUE' ) && !traffic.dt_entrada_fisica && order?.iconterms_pedido != 'CIF'">
            <app-progress-line class="svg-default-green" ></app-progress-line>
        </span>

        <span class="col mt-4 ml-3" *ngIf="traffic.dt_chegada || (traffic.status_fatura | uppercase) == 'ENTREGUE' || traffic.dt_entrada_fisica || order?.iconterms_pedido == 'CIF'" >
            <app-progress-line class="svg-success" ></app-progress-line>
        </span>
    </div>

    <div *ngIf=" traffic.status_fatura && order?.entrada_fisica || traffic.dt_entrada_fisica || (order?.faturas?.$values[0]?.num_fatura &&  order?.faturas?.$values[0]?.num_fatura != 0 )">
        <div  *ngIf="order?.iconterms_pedido != 'CIF'">
            <div class="row">

                <div class="col">
                    <app-vertical-line size="55" class="svg-success"></app-vertical-line>
                </div>
            </div>

            <div class="d-flex">
                <div class="pl-4 mt-n2">
                    <app-circle size="8" class="svg-success"></app-circle>
                </div>

                <div class="request__status__text ml-2 request__status__textCurrent" >

                    <div *ngIf="(traffic.status_fatura | uppercase) == 'AGUARDANDO COLETA'  && !traffic.dt_coleta && !traffic.dt_entrada_fisica">
                        
                        <span class='font-18'  translate>request.waitingCollection</span>
                        <br>
                        <div class="font-weight-bold">
                            <u class="c-pointer" *ngIf="order?.descricao_transportadora" (click)="linkTransport(order?.cod_transportadora)" > 
                                <span  class="mr-1" translate >{{order?.descricao_transportadora}}</span>
                                <app-external-link size="18" class="svg-stroke-gray-600" ></app-external-link>
                            </u>

                            <br>
                            <span translate> request.collectionForecast</span> : 
                            <span>{{traffic.dt_previsao_coleta | date: 'dd/MM/yyyy'}}</span>   
                        </div>
                        
                    </div>
                </div>


                    <div class="request__status__textCurrent"  *ngIf="(traffic.status_fatura | uppercase) == 'AGUARDANDO COLETA' && traffic.dt_coleta && !traffic.dt_entrada_fisica">
                        <span class='font-18' translate>request.waitingCollection</span>
                        <br>
                        
                        <div class="font-weight-bold">
                            <u class="c-pointer"  *ngIf="order?.descricao_transportadora" (click)="linkTransport(order?.cod_transportadora)" > 
                                <span  class="mr-1" translate >{{order?.descricao_transportadora}}</span>
                                <app-external-link size="18" class="svg-stroke-gray-600" ></app-external-link>
                            </u>

                            <br>
                            <span translate> request.collectionConfirmation</span>
                            <br>

                            <span>{{traffic.dt_coleta | date:  'dd/MM/yyyy'}}</span>
                        </div>
                        
                        
                    </div>    
                    
                    <div class="request__status__textCurrent" *ngIf="(traffic.status_fatura | uppercase) == 'EM TRÂNSITO' && !traffic.dt_entrada_fisica">
                        <span class="font-18"  translate>request.inTransit</span>
                        <br>
                        <div class="font-weight-bold">
                            <div *ngIf="traffic?.dt_confirmacao_coleta">
                                <span  translate> request.collected</span>
                                <br>
                            </div>
                            <u class="c-pointer" *ngIf="order?.descricao_transportadora" (click)="linkTransport(order?.cod_transportadora)" > 
                                <span  class="mr-1" translate >{{order?.descricao_transportadora}}</span>
                                <app-external-link size="18" class="svg-stroke-gray-600" ></app-external-link>
                            </u>

                            <br>
                            <span translate>request.deliveryForecast </span>
                            <br>
                            <span>{{traffic.dt_previsao_entrega  | date:  'dd/MM/yyyy'}}</span>
                        </div>
                        
                    </div>

                    <div class="request__status__textCurrent" *ngIf="(traffic.status_fatura | uppercase) == 'ENTREGUE' || traffic.dt_entrada_fisica">
                        <span class="font-18" translate>request.delivered</span>
                    
                    
                        <div class="font-weight-bold">
                            <u class="c-pointer" *ngIf="order?.descricao_transportadora" (click)="linkTransport(order?.cod_transportadora)" >
                                <br> 
                                <span  class="mr-1" translate >{{order?.descricao_transportadora}}</span>
                                <app-external-link size="18" class="svg-stroke-gray-600" ></app-external-link>
                            </u>
                            <br>
                            <span translate>request.arrivalBlueprint</span>
                            <br>
                            <span>{{traffic.dt_chegada  | date:'dd/MM/yyyy'}}</span>
                        </div>
                        
                    </div>



            </div>
        </div>
        
    </div>

</div>