import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { baseAPI } from "@app/constants";
import { ShippingCompanyModel } from "@app/model/shipping-company";
import { Observable } from "rxjs";
import { tap,map, switchMap } from 'rxjs/operators';
import Swal from "sweetalert2";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
  })
  
export class ShippingCompanyService {

    constructor(private http: HttpClient, private authService : AuthService) {

    }

      
    addShippingCompany(data: any): Observable<ShippingCompanyModel>  {    
        return this.authService.authToken()
        .pipe(switchMap(auth => {
            var headers = new HttpHeaders({
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${auth.data.token}`
            });

            return this.http.post(baseAPI + '/v1/transportadora/create', data.shippingCompany, {headers : headers})

        }))

    }

          
    getAllShippingCompany(): Observable<ShippingCompanyModel[]>  { 

        
        return this.authService.authToken()
        .pipe(switchMap(auth => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${auth.data.token}`
            });

            return this.http.get<ShippingCompanyModel[]>(baseAPI + '/v1/transportadora/get-all', {headers : headers})
        })
        )
    }

    getShippingCompany(codTr : any): Observable<any>  { 
        
        return this.authService.authToken()
        .pipe(switchMap(auth => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${auth.data.token}`
            });

            return this.http.get(baseAPI + '/v1/transportadora/get/'+codTr.codTr, {headers : headers})
        })
        )
    }
    removeShippingCompany(data: any): Observable<ShippingCompanyModel>  {   
            
        return this.authService.authToken()
        .pipe(switchMap(auth => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${auth.data.token}`
            });

            return this.http.delete(baseAPI + '/v1/transportadora/remove/'+data.codTR, {headers : headers})
       
        }))
    
    }

    removeAllShippingCompany(): Observable<ShippingCompanyModel>  {   
     
        return this.authService.authToken()
        .pipe(switchMap(auth => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${auth.data.token}`
            });

            return this.http.delete(baseAPI + '/v1/transportadora/removeAll', {headers : headers})
        }))
    }
}