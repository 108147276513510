<div class="row pt-3 pl-3">
    <div *ngIf="!verifyStatus "   class="col-1 mr-lg-2 mr-3  ml-lg-1">             
        <app-progress size="32" class1="svg-default-green" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
    </div>
    <div *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'VERIFICANDO SOBRA DE ESTOQUE'"   class="col-1 mr-lg-2 mr-3  ml-lg-1">             
        <app-progress size="32" class1="svg-success" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
    </div>

    <div *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'NÃO INICIADO'" class="col-1 mr-lg-2 mr-3  ml-lg-1">             
        <span *ngIf="item.grupo_compra_rc == 'INV'">
            <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </span>

        <div *ngIf="item.grupo_compra_rc != 'INV'">
            <app-progress size="32" class1="svg-success" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </div>
    </div>

    <div *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'EM COTAÇÃO'" class="col-1 mr-lg-2 mr-3  ml-lg-1">             
        <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-default-green"></app-progress>
    </div>

    <div *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'COTAÇÃO ENCERRADA'" class="col-1 mr-lg-2 mr-3  ml-lg-1">             
        <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
    </div>

    <div *ngIf="(item.cotacoes?.status_cotacao | uppercase) == 'POSSUI CONTRATO'" class="col-1 mr-lg-2 mr-3  ml-lg-1">             
        <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
    </div>


    <div  class="col-8 request__status font-14">
    
        <div *ngIf="(item.cotacoes?.status_cotacao | uppercase)  != 'EM COTAÇÃO' &&  (item.cotacoes?.status_cotacao | uppercase) != 'COTAÇÃO ENCERRADA'">
            
            <b class="request__status__success uppercase" translate> generic.quotation </b>
            <br>
                                    
            <span> {{item.cotacoes?.status_cotacao}} </span>

        </div>

        <div *ngIf="(item.cotacoes?.status_cotacao | uppercase)  == 'EM COTAÇÃO'">
            <b class="request__status__success uppercase"> {{item.cotacoes?.status_cotacao}} </b>

            <br>                                                            
            <span translate>request.forecast</span> :  {{item.cotacoes.dt_prevista_cotacao | date: 'dd/MM/yyyy'}}
            
        </div>
    
        <div *ngIf="(item.cotacoes?.status_cotacao | uppercase)  == 'COTAÇÃO ENCERRADA'">
            
            <b class="request__status__success uppercase">{{item.cotacoes?.status_cotacao}}</b>
            <br>
                                    
            <span> {{item.cotacoes?.dt_encerramento_cotacao | date: 'dd/MM/yyyy'}} </span>
            

        </div>
    
    </div>
</div>