<div class="row">

    <div class="col-auto selectAllP font-14 ">
        <div class="form-check form-check-inline">
            <input #elementcheck class="form-check-input rounded"  (click)="toggleCheck($event, '', true)" [checked]="checkboxSelect.length >= itemsPerPage" type="checkbox"
            id="all" >
            <label class="form-check-label mt-1 ml-1" for="all"  > 
                <span translate>generic.select</span>
                <span class="lowercase" translate> generic.all</span>
            </label>
        </div>
    </div>

    <div class="col-auto c-pointer rounded textFollow" (click)="checkboxSelect.length > 0 ? openDialog() : null" [ngClass]="{ 'followEmail': checkboxSelect.length === 0, 'followEmail__all': checkboxSelect.length > 0 }">
        <app-email size="20" *ngIf="checkboxSelect.length == 0 " class="svg-gray-300" > </app-email>
        <app-email size="20" *ngIf="checkboxSelect.length > 0" class="svg-gray-800" > </app-email>
        <span class="ml-1 mt-1" translate>request.followUpByEmail</span>
    </div>

    <div *ngIf="checkboxSelect.length > 0" class="col-auto textFollow">
        <span translate >request.allRequest</span> 
        <b> {{qtdSelect}} </b>  
        <span translate> request.requestPageSelect</span> 
     
    </div>

    <div *ngIf="checkboxSelect.length < items.length && checkboxSelect.length > 0" (click)="toggleCheck('checked','','', true)"   class="col-auto textFollow c-pointer">
        <u>
            <span translate>request.selectAllList</span>
            <b> {{items.length}}</b>  
            <span translate> request.requestList</span>
        </u>
    </div>

</div>

<div class="row">
    <div class="col-12 modalText dialog-content mt-5" *ngIf="loading$ | async">
        <app-loading size="60"></app-loading>
    </div>
</div>

<div class="mb-5 pb-5 listRequest overflow">

    <div class="request font-12" [ngClass]="{'grayRequest': i  % 2 }"  *ngFor="let item of items | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }; let i = index">
       
        <div *ngIf="item.pedido_requisicao?.$values.length <= 0 && item.cotacoes?.pedido_cotacao?.$values.length <= 0"> 
            <div class="row">

                 <div class="col-lg-4 col-md-4 col-sm-12" >
                    <div class="request__info">   
                        <div> 
                            <div class="row">
                                <div class="col-1 ml-n2 mt-2">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input rounded checkbox" (change)="toggleCheck($event, item)" [checked]="checkboxSelect.includes(item.$id)" type="checkbox" [id]="item.$id" >
                                        <label class="form-check-label" [for]="item.$id"></label>
                                    </div>
                                </div>
            
                                <div class="col-1 ml-n2"> 
                                    <button *ngIf="indexExpanded != i"  (click)="indexExpanded = indexExpanded == i ? -1 : i"  class=" collapsible mr-2 btn btn-sm btn-outline-info">
                                        <app-arrow-down class="svg-stroke-secondary" size="11"></app-arrow-down>
                                    </button>
                                    <button *ngIf="indexExpanded == i" (click)="indexExpanded = indexExpanded == i ? -1 : i"  class="mr-2 btn btn-sm btn-outline-info">
                                        <app-arrow-up class="svg-stroke-secondary" size="11"></app-arrow-up>
                                    </button>
                                </div>



                                
            
                                <div class="col-10">
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="request__info__title font-weight-bold" *ngIf="item.descricao_mat"> {{item.descricao_mat}} </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <span size="11" class="request__info__number mr-1" *ngIf="item.cod_material"> {{ item.cod_material | int}} </span> 
        
                                            <span class="request__info__number__divider">|</span>
                                            <span class="request__info__number"> {{item.quant_rc}} {{item.unidade_rc}} </span> 
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
            
                        </div>
                        <hr class="d-lg-block d-none request__info__divider">
                    </div>
                </div>
            
                <div class="col-lg-5 col-md-5">
                    
                    <div class="row request__info">
                        <div class="col-12 mt-1">
                            <div class="row">
            
                                <div class="col mt-lg-0">
                                    <b class="uppercase" translate>generic.request</b>
                                    <br>
                                    <span>{{ item.cod_requisicao }}</span> 
                                </div>
            
                                <!-- ngIf request.item_rc -->
                                <div class="col mt-lg-0">
                                    <b class="uppercase" translate>generic.line</b>
                                    <br>
                                    <!-- request.item_rc -->
                                    <span>{{item.item_rc}}</span>
                                </div>
                                <div class="col mt-lg-0">
                                    <b class="uppercase" translate>generic.order</b>
                                    <br>
                                    <!-- request.pedido_requisicao.num_pedido -->
                                    <span> - </span>
                                </div>
            
                                <div class="col mt-lg-0">
                                     <b class="uppercase" translate>request.orderLine</b>
                                     <br>
                                    <span>-</span>
                                        
                                </div>
                            </div>              
            
                        </div>
                        <hr class="d-lg-block d-none  request__info__divider"> 
                    </div>
                </div>
            
                <!-- last status request-->
                <div *ngIf="item.status_rc && !item.cotacoes?.status_cotacao" class="col-xl-2 col-md-2 col-lg-3 col-sm-12 mb-1">
                    
                    <app-request-status [item]="item"></app-request-status>

                </div>
    

                <!-- last status -->
                <div *ngIf="item.cotacoes?.status_cotacao && !item.cotacoes?.pedido_cotacao?.$values.status_pedido && !item.pedido_requisicao.$values.status_pedido" class="col-lg-3 col-sm-12 mb-1">
               
                    <app-quote-status [item]="item"> </app-quote-status>

                </div>

            </div>                

            <div class="animate__animated animate__fadeIn" [hidden]="indexExpanded != i ? true : false">
                <app-request-details [item]="item" [index]="i" [items]="items"></app-request-details >
            </div>           
        </div>

        <div *ngIf="item.cotacoes?.pedido_cotacao?.$values.length > 0">
            <div *ngFor="let pedido of item.cotacoes?.pedido_cotacao?.$values " >
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12" >
                        <div class="request__info">   
                            <div> 
                                <div class="row">
                                    <div class="col-1 ml-n2 mt-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input rounded checkbox" (change)="toggleCheck($event, item, '', '', pedido)" [checked]="checkboxSelect.includes(pedido.$id)" type="checkbox" [id]="pedido.$id" >
                                            <label class="form-check-label" [for]="pedido.$id"></label>
                                        </div>
                                     </div>
                
                                    <div class="col-1 ml-n2"> 
                                        <button *ngIf="indexExpanded != pedido.$id"  (click)="indexExpanded = indexExpanded == pedido.$id ? -1 :  pedido.$id " class=" collapsible mr-2 btn btn-sm btn-outline-info">
                                            <app-arrow-down class="svg-stroke-secondary" size="11"></app-arrow-down>
                                        </button>
                                        <button ngIf="indexExpanded != pedido.$id"  (click)="indexExpanded = indexExpanded == pedido.$id ? -1 :  pedido.$id " class="mr-2 btn btn-sm btn-outline-info">
                                            <app-arrow-up class="svg-stroke-secondary" size="11"></app-arrow-up>
                                        </button>
                                    </div>
                
                                    <div class="col-10">
                                        <div class="row">
                                            <div class="col-12">
                                                <span class="request__info__title font-weight-bold" *ngIf="pedido.descricao_mat_pedido  "> {{pedido.descricao_mat_pedido}} </span>
                                                <span class="request__info__title font-weight-bold" *ngIf="!pedido.descricao_mat_pedido && item.descricao_mat"> {{item.descricao_mat}} </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <span size="11" class="request__info__number mr-1" *ngIf="pedido.cod_material_pedido"> {{ pedido.cod_material_pedido | int }} </span> 
                                                <span size="11" class="request__info__number mr-1" *ngIf="!pedido && item.cod_material"> {{ item.cod_material | int }} </span> 
            
                                                <span class="request__info__number__divider">|</span>
                                                <span class="request__info__number" *ngIf="!pedido.quant_pedido && !pedido.unidade_pedido"> {{item.quant_rc}} {{item.unidade_rc}} </span> 
                                                <span class="request__info__number" *ngIf="pedido.quant_pedido || pedido.unidade_pedido"> {{pedido.quant_pedido}} {{pedido.unidade_pedido}} </span> 
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                
                            </div>
                            <hr class="d-lg-block d-none request__info__divider">
                        </div>
                    </div>
                
                    <div class="col-lg-5 col-md-5">
                        
                        <div class="row request__info">
                            <div class="col-12 mt-1">
                                <div class="row">
                
                                    <div class="col mt-lg-0">
                                        <b class="uppercase" translate>generic.request</b>
                                        <br>
                                        <span>{{ item.cod_requisicao }}</span> 
                                    </div>
                
                                    <!-- ngIf request.item_rc -->
                                    <div class="col mt-lg-0">
                                        <b class="uppercase" translate>generic.line</b>
                                        <br>
                                        <!-- request.item_rc -->
                                        <span >{{item.item_rc}}</span>
                                    </div>
                                    <div class="col mt-lg-0">
                                        <b class="uppercase" translate>generic.order</b>
                                        <br>
                                        <!-- request.pedido_requisicao.num_pedido -->
                                        <span>{{pedido.num_pedido}}</span>
                                    </div>
                
                                    <div class="col mt-lg-0">
                                        
                                        <b class="uppercase" translate>request.orderLine</b>
                                        <br>
                                       <span *ngIf="pedido.item_pedido">{{pedido.item_pedido}}</span>
                                           
                                            
                                    </div>
                                </div>              
                
                            </div>
                            <hr class="d-lg-block d-none  request__info__divider"> 
                        </div>
                    </div>
                
                    <!-- last status request-->
                    <div *ngIf="item.status_rc && !item.cotacoes?.status_cotacao" class="col-xl-2 col-md-3 col-lg-3 col-sm-12 mb-1">
                        <app-request-status [item]="item"></app-request-status>
                
                    </div>

                    <!-- status quote  -->
                    <div *ngIf="item.cotacoes?.status_cotacao && !pedido.status_pedido" class="col-xl-2 col-md-2 col-lg-3 col-sm-12 mb-1">
                        <app-quote-status [item]="item"></app-quote-status>
    
                    </div>

                    <!-- status order-->
                    <div *ngIf="pedido.status_pedido && !pedido.faturas?.$values[0]?.status_fatura && pedido.faturas?.$values[0]?.status_recebimento && pedido.entrada_fisica  " class="col-xl-2 col-md-2 col-lg-3  col-sm-12 mb-1">
                        <app-order-status [item]="pedido"></app-order-status>

                    </div>
                    
                    <!-- status traffic -->
                    <div *ngIf="pedido.faturas?.$values?.length > 0 && pedido.faturas?.$values[0]?.status_fatura && !pedido.faturas?.$values[0]?.status_recebimento &&  pedido?.faturas?.$values[0]?.num_fatura && pedido?.faturas?.$values[0]?.num_fatura != 0 && !pedido.faturas?.$values[0]?.dt_entrada_fisica" class="col-xl-2 col-md-2 col-lg-3 col-sm-12 mb-1">
                        <app-traffic-status [item]="pedido" ></app-traffic-status>
                    </div>
    
                    <!-- status receiving -->
                    <div *ngIf="(pedido.faturas?.$values?.length > 0  && (pedido.faturas?.$values[0]?.status_recebimento || pedido.faturas?.$values[0]?.dt_entrada_fisica  )) || !pedido.entrada_fisica " >
                        <app-receivement-status [item]="pedido"></app-receivement-status>
                    </div>
                    
                </div>
                
                <div class="animate__animated animate__fadeIn" [hidden]="indexExpanded != i ? true : false">
                    <app-request-details  [item]="item"  [order]="pedido" [index]="i" [items]="item.cotacoes?.pedido_cotacao?.$values"></app-request-details >
                </div>
            </div>
            
        </div>

        <div *ngIf="item.pedido_requisicao?.$values.length > 0 ">
            <div *ngFor="let pedido of item.pedido_requisicao.$values;let p = index"  [ngClass]="{'grayRequest': p  % 2 }"  >


                <div class="row" >
                    <div class="col-lg-4  col-md-4 col-sm-12" >
                        <div class="request__info">   
                            <div> 
                                <div class="row">
                                    <div class="col-1  ml-n2 mt-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input rounded checkbox" (change)="toggleCheck($event, item, '', '', pedido)" [checked]="checkboxSelect.includes(pedido.$id)" type="checkbox" [id]="pedido.$id" >
                                            <label class="form-check-label" [for]="pedido.$id"></label>
                                        </div>
                                    </div>
            
                                    <div class="col-1 ml-n2"> 
                                        <button *ngIf="indexExpanded != pedido.$id"  (click)="indexExpanded = indexExpanded == pedido.$id ? -1 :  pedido.$id "  class=" collapsible mr-2 btn btn-sm btn-outline-info">
                                            <app-arrow-down class="svg-stroke-secondary" size="11"></app-arrow-down>
                                        </button>
                                        <button *ngIf="indexExpanded == pedido.$id" (click)="indexExpanded = indexExpanded == pedido.$id ? -1 :  pedido.$id "  class="mr-2 btn btn-sm btn-outline-info">
                                            <app-arrow-up class="svg-stroke-secondary" size="11"></app-arrow-up>
                                        </button>
                                    </div>
                                 
                                    <div class="col-10">
                                        <div class="row">
                                            <div class="col-12">
                                                <span class="request__info__title font-weight-bold" *ngIf="pedido.descricao_mat_pedido"> {{pedido.descricao_mat_pedido}} </span>
                                                <span class="request__info__title font-weight-bold" *ngIf="!pedido.descricao_mat_pedido && item.descricao_mat"> {{item.descricao_mat}} </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <span size="11" class="request__info__number mr-1" *ngIf="pedido.cod_material_pedido"> {{ pedido.cod_material_pedido | int }} </span> 
                                                <span size="11" class="request__info__number mr-1" *ngIf="!pedido && item.cod_material"> {{ item.cod_material | int }} </span> 
            
                                                <span class="request__info__number__divider">|</span>
                                                <span class="request__info__number" *ngIf="item.quant_rc && !pedido.quant_pedido"> {{item.quant_rc}} {{item.unidade_rc}} </span> 
                                                <span class="request__info__number" *ngIf="pedido.quant_pedido"> {{pedido.quant_pedido}} {{pedido.unidade_pedido}} </span> 
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                
                            </div>
                            <hr class="d-lg-block d-none request__info__divider">
                        </div>
                    </div>
                
                    <div class="col-xl-5 col-md-5 col-lg-5">
                        
                        <div class="row request__info">
                            <div class="col-12 mt-1">
                                <div class="row">
                
                                    <div class="col mt-lg-0">
                                        <b class="uppercase" translate>generic.request</b>
                                        <br>
                                        <span>{{ item.cod_requisicao }}</span> 
                                    </div>
                
                                    <!-- ngIf request.item_rc -->
                                    <div class="col mt-lg-0">
                                        <b class="uppercase" translate>generic.line</b>
                                        <br>
                                        <!-- request.item_rc -->
                                        <span >{{item.item_rc}}</span>
                                    </div>

                                    <div class="col mt-lg-0">
                                        <b class="uppercase" translate>generic.order</b>
                                        <br>
                                        <!-- request.pedido_requisicao.num_pedido -->
                                        <span>{{pedido.num_pedido}}</span>
                                    </div>
                
                                    <div  class="col mt-lg-0">
                                        
                                        
                                        <b class="uppercase" translate>request.orderLine</b>
                                        <br>
                                       <span *ngIf="pedido?.item_pedido">{{pedido?.item_pedido}}</span>
                                           
                                    </div>
                                </div>              
                
                            </div>
                            <hr class="d-lg-block d-none  request__info__divider"> 
                        </div>
                    </div>
                                
                    <!-- last status request-->
                    <div *ngIf="item.status_rc && !pedido.status_pedido " class="col-xl-2 col-md-2 col-lg-3 col-sm-12 mb-1">
                        <app-request-status [item]="item"></app-request-status>
                    </div>

                    <div *ngIf="pedido.iconterms_pedido != 'CIF'">

                        <!-- status order -->
                        <div *ngIf="pedido.status_pedido && !pedido.faturas?.$values[0]?.status_fatura  &&  !pedido.faturas?.$values[0]?.status_recebimento && pedido.entrada_fisica" class="col-xl-2 col-md-2 col-lg-3 col-sm-12 mb-1">
                            <app-order-status [item]="pedido"></app-order-status>
                        </div> 
                        

                        <!-- status traffic -->
                        <div *ngIf="pedido.faturas?.$values?.length > 0  && pedido.faturas?.$values[0]?.status_fatura && !pedido.faturas?.$values[0]?.status_recebimento && !pedido.faturas?.$values[0]?.dt_entrada_fisica" class="col-xl-2 col-md-2 col-lg-3 col-sm-12 mb-1">
                            <app-traffic-status [item]="pedido"></app-traffic-status>
                        </div>

                    </div>

                    <div *ngIf="pedido.iconterms_pedido == 'CIF'">
                      <!-- status order -->
                        <div *ngIf="pedido.status_pedido  &&  !pedido.faturas?.$values[0]?.status_recebimento && pedido.entrada_fisica" class="col-xl-2 col-md-2 col-lg-3 col-sm-12 mb-1">
                            <app-order-status [item]="pedido"></app-order-status>
                        </div> 
                        
                    </div>
            
                    <!-- status receiving -->
                    <div *ngIf="(pedido.faturas?.$values?.length > 0  && (pedido.faturas?.$values[0]?.status_recebimento || pedido.faturas?.$values[0]?.dt_entrada_fisica)) || !pedido.entrada_fisica  " >
                        <app-receivement-status [item]="pedido"></app-receivement-status>   
                    </div>
                </div>

                <div class="animate__animated animate__fadeIn" [hidden]="indexExpanded !=  pedido.$id   ? true : false">
                    <app-request-details [items]="item.pedido_requisicao.$values"  [item]="item" [order]="pedido" [index]="i"></app-request-details >
                </div>

            </div>
            

        </div>

    </div>
</div>

