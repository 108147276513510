import { Component, Input, OnInit } from '@angular/core';
import { TrackingRequestsService } from '../../tracking-requests.service';

@Component({
  selector: 'app-quote-flow',
  templateUrl: './quote-flow.component.html',
  styleUrls: ['./quote-flow.component.scss']
})
export class QuoteFlowComponent implements OnInit {
  @Input('item') item: any
  @Input('order') order?: any
  verifyStatus : Boolean = true;
  quoteStatus = ['VERIFICANDO SOBRA DE ESTOQUE', 'NÃO INICIADO', 'EM COTAÇÃO', 'COTAÇÃO ENCERRADA', 'MATERIAL POSSUI CONTRATO']
  constructor(private trackingRequestsService : TrackingRequestsService) { }

  ngOnInit(): void {
    if(this.item.cotacoes?.status_cotacao){
      this.verifyStatus  = this.trackingRequestsService.verifyStatus(this.item.cotacoes?.status_cotacao, this.quoteStatus)
    }

  }


}
