import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-receivement-status',
  templateUrl: './receivement-status.component.html',
  styleUrls: ['./receivement-status.component.scss']
})
export class ReceivementStatusComponent implements OnInit {
  @Input() item : any;
  constructor() { }

  ngOnInit(): void {
  }

}
