import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '@app/services/loading.service';
import { RequestService } from '@app/services/request.service';
import { imagePath } from "@constants";
import { Store } from '@ngrx/store';
import { pull } from 'lodash';
import { Observable } from 'rxjs';
import { InputService } from '../form/input.service';
import { ModalEmailComponent } from '../modal-email/modal-email.component';
import { ShippingCompanyModel } from '@app/model/shipping-company';

@Component({
  selector: 'app-tracking-requests',
  templateUrl: './tracking-requests.component.html',
  styleUrls: ['./tracking-requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default

})
export class TrackingRequestsComponent implements OnChanges {
  currentStatus : boolean = true;
  requestDetails : boolean = false; 
  imagePath : string = imagePath;
  indexExpanded:number=-1
  qtdSelect : number = 0
  checkboxSelect :any[]  = [];
  dataEmailRequest : any[] = []
  @Input('items') items: any = []
  @Input('itemsPerPage') itemsPerPage : number = 10
  @Input('currentPage') currentPage : number = 1
  loading$ = this.loader.loading$;
  checkAll  = false
  @ViewChild('elementcheck')  elementcheck?: ElementRef;
  readonly shippingCompanyList$: Observable<ShippingCompanyModel[]> = this.store.select('shippingCompany');

  constructor(private store: Store<any>, public dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef, public loader: LoadingService, public el :ElementRef,private renderer2: Renderer2 ) { 
    
  }
  
  ngOnChanges() {   

    if(this.checkAll == false){
      this.checkboxSelect = [];
    }
    
    this.changeDetectorRef.detectChanges();
  }

  openDialog() {
  
    const dialogRef = this.dialog.open(ModalEmailComponent, { data : {items : 3}});
    let instance = dialogRef.componentInstance;

    instance.infos = {
      dataEmailRequest : this.dataEmailRequest,
      checkItems: this.checkboxSelect.length,
      email: localStorage.getItem('email')
    }


    dialogRef.afterClosed().subscribe(result => {
    });
  
  }


  toggleCheck(event : any, item? : any, allPage? : any, all? : any, order? : any){

    if(allPage){
      this.checkAll = false;      
      this.checkboxSelect = []
      this.dataEmailRequest = []
      if(event.target?.checked || event == 'checked'){

        var indexInitial = 0
        if(this.currentPage >= 3 ){
           indexInitial = ((this.currentPage - 1) * this.itemsPerPage );
        }else if(this.currentPage == 2){
           indexInitial  =  this.itemsPerPage;
        }

        this.items.forEach((element :any, index : number) => {
          if(index >= indexInitial){
            if(this.checkboxSelect.length <= (this.itemsPerPage - 1) ){
              
              if(element.cotacoes?.pedido_cotacao?.$values.length > 0){
                     
                  element.cotacoes?.pedido_cotacao?.$values.map((item: any) => {
                    
                    if(!this.checkboxSelect.includes(item.num_pedido)){
                      var cod_req = 0
                      element.cod_requisicao ? cod_req = element.cod_requisicao : cod_req = item.num_pedido
                      if(!item.faturas?.$values[0]?.status_fatura){
                                         
                        this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.status_cotacao+'',  "email" :  localStorage.getItem('email')})
                   
                      }else if (item.faturas?.$values[0]?.status_fatura && !item.faturas?.$values[0]?.status_recebimento){
  
                        this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.faturas?.$values[0]?.status_fatura+'',  "email" :  localStorage.getItem('email')})
  
                      }else if (item.faturas?.$values[0]?.status_recebimento){
  
                        this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.faturas?.$values[0]?.status_recebimento+'',  "email" :  localStorage.getItem('email')})
  
                      } 
                    
                    }
                    this.checkboxSelect.push(item.$id) 
                 
                  })
           
                }else if (element.pedido_requisicao?.$values.length > 0){
  
                  element.pedido_requisicao?.$values.map((item: any) => {
                   
                    if(!this.checkboxSelect.includes(item.num_pedido)){
            
                      var cod_req = 0
                      element.cod_requisicao ? cod_req = element.cod_requisicao : cod_req = item.num_pedido
  
                   
                      if(!item.faturas?.$values[0]?.status_fatura && !item.status_pedido && item.status_RC){
                        this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.status_rc+'',  "email" :  localStorage.getItem('email')})
                      }else if(!item.faturas?.$values[0]?.status_fatura && item.status_pedido){
                        this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.status_pedido+'',  "email" :  localStorage.getItem('email')})
  
                      }else if (item.faturas?.$values[0]?.status_fatura && !item.faturas?.$values[0]?.status_recebimento){
                       
                        this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.faturas?.$values[0]?.status_fatura+'',  "email" :  localStorage.getItem('email')})
  
                      }else if (item.faturas?.$values[0]?.status_recebimento){
                      
                        this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.faturas?.$values[0]?.status_recebimento+'',  "email" :  localStorage.getItem('email')})
  
                      }

                    }
           
                    this.checkboxSelect.push(item.$id) 
                 
                  })
                         
                }else{
                  if(!element.cotacoes?.status_cotacao){
                    this.dataEmailRequest.push({ "rC_sap" : ''+element.cod_requisicao+'', "status_RC" :  ''+element.status_rc+'',  "email" :  localStorage.getItem('email')}) 
                  }else{
                    this.dataEmailRequest.push({ "rC_sap" : ''+element.cod_requisicao+'', "status_RC" :  ''+element.cotacoes?.status_cotacao+'',  "email" :  localStorage.getItem('email')}) 
  
                  }
                  this.checkboxSelect.push(element.$id)
  
                }
              
            }
          }
      
          

        })
    
        
      }
    
    }else if(all){
  
      this.checkboxSelect = []
        
      this.dataEmailRequest = []

      this.items.forEach((element : any) => {
     
        if(element.cotacoes?.pedido_cotacao?.$values.length > 0){
                   
          element.cotacoes?.pedido_cotacao?.$values.map((item: any) => {
  
            if(!this.checkboxSelect.includes(item.$id)){
    
              var cod_req = 0
              element.cod_requisicao ? cod_req = element.cod_requisicao : cod_req = item.num_pedido

              if(!item.faturas?.$values[0]?.status_fatura){
            
                this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.status_cotacao+'',  "email" :  localStorage.getItem('email')})
           
              }else if (item.faturas?.$values[0]?.status_fatura && !item.faturas?.$values[0]?.status_recebimento){
                this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.faturas?.$values[0]?.status_fatura+'',  "email" :  localStorage.getItem('email')})

              }else if (item.faturas?.$values[0]?.status_recebimento){
                this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.faturas?.$values[0]?.status_recebimento+'',  "email" :  localStorage.getItem('email')})

              }          
            }
            this.checkboxSelect.push(item.$id) 
         
          })
   
        }else if (element.pedido_requisicao?.$values.length > 0){
          element.pedido_requisicao?.$values.map((item: any) => {

            if(!this.checkboxSelect.includes(item.$id)){
                    var cod_req = 0
                    element.cod_requisicao ? cod_req = element.cod_requisicao : cod_req = item.num_pedido
                

                    if(!item.faturas?.$values[0]?.status_fatura && !item.status_pedido && item.status_RC){
                      this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.status_rc+'',  "email" :  localStorage.getItem('email')})
                    }else if(!item.faturas?.$values[0]?.status_fatura && item.status_pedido){
                      this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.status_pedido+'',  "email" :  localStorage.getItem('email')})

                    }else if (item.faturas?.$values[0]?.status_fatura && !item.faturas?.$values[0]?.status_recebimento){
                      this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.faturas?.$values[0]?.status_fatura+'',  "email" :  localStorage.getItem('email')})

                    }else if (item.faturas?.$values[0]?.status_recebimento){
                      this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+item.faturas?.$values[0]?.status_recebimento+'',  "email" :  localStorage.getItem('email')})

                    }
              this.checkboxSelect.push(item.$id) 

            }
         
          })
                 
        }else{

          if(!item.cotacoes?.status_cotacao){
            this.dataEmailRequest.push({ "rC_sap" : ''+item.cod_requisicao+'', "status_RC" : ''+item.status_rc+'',  "email" : localStorage.getItem('email')}) 
  
          }else{
            this.dataEmailRequest.push({ "rC_sap" : ''+item.cod_requisicao+'', "status_RC" : ''+item.cotacoes?.status_cotacao+'',  "email" : localStorage.getItem('email')}) 
  
          }
          this.checkboxSelect.push(element.$id)

        }
      
 

      })

      this.checkAll = true;      

    }else if(event.target.checked){     
      this.checkAll = false;      
      this.dataEmailRequest = Object.assign([], this.dataEmailRequest);

      if (order){  

        var cod_req = 0
        item.cod_requisicao ? cod_req = item.cod_requisicao : cod_req = order.num_pedido

        if(!this.checkboxSelect.includes(order.$id)){
        
          if(!order.faturas?.$values[0]?.status_fatura){
            this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+order.status_pedido+'',  "email" :  localStorage.getItem('email')})
         
          }else if (order.faturas.$values[0].status_fatura && !order.faturas.$values[0].status_recebimento){
            this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+order.faturas.$values[0].status_fatura+'',  "email" :  localStorage.getItem('email')})

          }else if (order.faturas.$values[0].status_recebimento){
            this.dataEmailRequest.push({"rC_sap" : ''+cod_req+'', "status_RC" :  ''+order.faturas.$values[0].status_recebimento+'',  "email" :  localStorage.getItem('email')})

          }

        }
        this.checkboxSelect.push(order.$id) 
     
      }else{

        if(!item.cotacoes?.status_cotacao){

          this.dataEmailRequest.push({ "rC_sap" : ''+item.cod_requisicao+'', "status_RC" : ''+item.status_rc+'',  "email" : localStorage.getItem('email')}) 

        }else{

          this.dataEmailRequest.push({ "rC_sap" : ''+item.cod_requisicao+'', "status_RC" : ''+item.cotacoes?.status_cotacao+'',  "email" : localStorage.getItem('email')}) 

        }
        this.checkboxSelect.push(item.$id)

      }
      
     
    
    }else if(!event.target.checked){
      this.checkAll = false;      

      var rc = 0
      var rc_check = 0
      this.dataEmailRequest = Object.assign([], this.dataEmailRequest);

      item.cod_requisicao ? rc = item.cod_requisicao : rc = order.num_pedido ;
      order ? rc_check = order.$id : rc_check = item.$id;

      pull(this.checkboxSelect, rc_check) 


      this.dataEmailRequest.filter(res => res.rC_sap == rc).map((res, i) => {
       
        this.dataEmailRequest.splice(i);        
      });

    } 

    this.qtdSelect = this.checkboxSelect.length
    
    
  }


} 

