import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './input/input.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '@app/translator';
import { DatepickerRangeComponent } from './datepicker-range/datepicker-range.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '../icons/icons.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioComponent } from './radio/radio.component';
import { SelectComponent } from './select/select.component';
import { DatePickerMaterialModule } from './datepicker-range/datepicker-range.module';
import { InputTagComponent } from './input-tag/input-tag.component';

@NgModule({
  declarations: [
    InputComponent,
    DatepickerRangeComponent,
    CheckboxComponent,
    RadioComponent,
    SelectComponent,
    InputTagComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    IconsModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DatePickerMaterialModule,
    
  
  ],
  exports: [
    ReactiveFormsModule,
    InputComponent,
    DatepickerRangeComponent,
    CheckboxComponent,
    RadioComponent,
    SelectComponent,
    InputTagComponent
    ],
  providers: [],
})

export class CustomFormModule { }
