<div class="row pt-3 pl-3 ">
    <div *ngIf="(item.faturas.$values[0].status_fatura | uppercase)  == 'AGUARDANDO COLETA'" class="col-1 mr-lg-3 mr-3  ml-lg-1">             
        <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-default-green" class4="svg-default-green"></app-progress>
    </div>
    <div *ngIf="(item.faturas.$values[0].status_fatura | uppercase)  == 'EM TRÂNSITO' && !item.faturas.$values[0].dt_entrada_fisica" class="col-1 mr-lg-3 mr-3  ml-lg-1">             
        <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-default-green"></app-progress>
    </div>

    <div *ngIf="(item.faturas.$values[0].status_fatura | uppercase) == 'ENTREGUE'  && !item.faturas.$values[0].dt_entrada_fisica" class="col-1 mr-lg-3 mr-3  ml-lg-1">             
        <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
    </div>

    <div  class="col-8 request__status font-14">
        <div *ngIf="(item.faturas.$values[0].status_fatura | uppercase) == 'AGUARDANDO COLETA'">
            
            <b class="request__status__success uppercase">{{item.faturas.$values[0].status_fatura}}</b>
            <br>
                                    
            <span> {{item.faturas.$values[0].dt_previsao_coleta  | date: 'dd/MM/yyyy'}}  </span>
            
        </div>

        <div *ngIf="(item.faturas.$values[0].status_fatura | uppercase)  == 'EM TRÂNSITO' && !item.faturas.$values[0].dt_entrada_fisica">
            
            <b class="request__status__success uppercase">{{item.faturas.$values[0].status_fatura}}</b>
            <br>
                                    
            <span> {{item.faturas.$values[0].dt_previsao_entrega  | date: 'dd/MM/yyyy'}}  </span>
            
        </div>


        <div *ngIf="(item.faturas.$values[0].status_fatura | uppercase) == 'ENTREGUE' && !item.faturas.$values[0].dt_entrada_fisica ">
            
            <b class="request__status__success uppercase">{{item.faturas.$values[0].status_fatura}}</b>
            <br>
                                    
            <span> {{item.faturas.$values[0].dt_chegada  | date: 'dd/MM/yyyy'}}  </span>
            
        </div>
    </div>
</div>