import { Injectable } from '@angular/core';
import { Dimensions, DimensionUncovered } from './dimensions.model';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  constructor() { }

  setDimensions( dimensions : DimensionUncovered) : Dimensions{
      
      const initialSize : number = parseInt(dimensions.size);
      const width = initialSize;
      const height = (initialSize * dimensions.height) / dimensions.width;

      const dimension : Dimensions = {width , height}

      return dimension;
      
  }


}
