import { AccessManagementModel } from "@app/model/access-management";
import { ShippingCompanyModel } from "@app/model/shipping-company";
import { Action } from "@ngrx/store";

export const ALL_SHIPPING_COMPANY = '[ SHIPPING COMPANY GET ALL] Access Shipping Company ';
export const ALL_SHIPPING_COMPANY_ERROR = '[ SHIPPING COMPANY GET ALL ERROR] Access Shipping Company Error';
export const ALL_SHIPPING_COMPANY_SUCCESS = '[ SHIPPING COMPANY GET ALL SUCCESS ] Access Shipping Company Success';

export const ADD_SHIPPING_COMPANY = '[ADD SHIPPING COMPANY] Access Shipping Company';
export const ADD_SHIPPING_COMPANY_ERROR = '[ADD SHIPPING COMPANY ERROR] Access Shipping Company Error';
export const ADD_SHIPPING_COMPANY_SUCCESS = '[ADD SHIPPING SUCCESS] Access Shipping Company Success';

export const REMOVE_SHIPPING_COMPANY = '[REMOVE SHIPPING COMPANY] Access Shipping Company';
export const REMOVE_SHIPPING_COMPANY_ERROR = '[REMOVE SHIPPING COMPANY ERROR] Access Shipping Company Error';
export const REMOVE_SHIPPING_COMPANY_SUCCESS = '[REMOVE SHIPPING SUCCESS] Access Shipping Company Success';

export const REMOVE_ALL_SHIPPING_COMPANY = '[REMOVE ALL SHIPPING COMPANY] Access Shipping Company';
export const REMOVE_ALL_SHIPPING_COMPANY_ERROR = '[REMOVE ALL SHIPPING COMPANY ERROR] Access Shipping Company Error';
export const REMOVE_ALL_SHIPPING_COMPANY_SUCCESS = '[REMOVE ALL SHIPPING SUCCESS] Access Shipping Company Success';

export const GET_SHIPPING_COMPANY = '[ SHIPPING COMPANY ] Access Shipping Company ';
export const GET_SHIPPING_COMPANY_ERROR = '[ SHIPPING COMPANY ERROR] Access Shipping Company Error';
export const GET_SHIPPING_COMPANY_SUCCESS = '[ SHIPPING COMPANY SUCCESS ] Access Shipping Company Success';


export class AddShippingCompany implements Action {
    
    readonly type = ADD_SHIPPING_COMPANY;

    constructor ( public shippingCompany : ShippingCompanyModel) {}
}

export class AddShippingCompanyError implements Action {
    readonly type = ADD_SHIPPING_COMPANY_ERROR;

    constructor (public payload: any) {}
}

export class  AddShippingCompanySuccess implements Action {
   
    readonly type = ADD_SHIPPING_COMPANY_SUCCESS;
 
    constructor ( public shippingCompany  : ShippingCompanyModel ) {}
}

export class GetAllShippingCompany implements Action {
    readonly type = ALL_SHIPPING_COMPANY;

    constructor () {}
}

export class GetAllShippingCompanyError implements Action {
    readonly type = ALL_SHIPPING_COMPANY_ERROR;

    constructor (public payload: any) {}
}

export class GetAllShippingCompanySuccess implements Action {

    readonly type = ALL_SHIPPING_COMPANY_SUCCESS;
    
    constructor ( public shippingCompany : ShippingCompanyModel[] ) {}
}

export class RemoveShippingCompany implements Action {
    readonly type = REMOVE_SHIPPING_COMPANY;

    constructor (public codTR : ShippingCompanyModel ) {}
}

export class RemoveShippingCompanyError implements Action {
    readonly type = REMOVE_SHIPPING_COMPANY_ERROR;

    constructor (public payload: any) {}
}

export class RemoveShippingCompanySuccess implements Action {

    readonly type = REMOVE_SHIPPING_COMPANY_SUCCESS;
    
    constructor ( public shippingCompany : any ) {}
}


export class RemoveAllShippingCompany implements Action {
    readonly type = REMOVE_ALL_SHIPPING_COMPANY;

}

export class RemoveAllShippingCompanyError implements Action {
    readonly type = REMOVE_ALL_SHIPPING_COMPANY_ERROR;

    constructor (public payload: any) {}
}

export class RemoveAllShippingCompanySuccess implements Action {

    readonly type = REMOVE_ALL_SHIPPING_COMPANY_SUCCESS;
    
    constructor ( public shippingCompany : any ) {}
}


export class GetShippingCompany implements Action {
    readonly type = GET_SHIPPING_COMPANY;

    constructor (public codTr? : number) {}
}

export class GetShippingCompanyError implements Action {
    readonly type = GET_SHIPPING_COMPANY_ERROR;

    constructor (public payload: any) {}
}

export class GetShippingCompanySuccess implements Action {

    readonly type = GET_SHIPPING_COMPANY_SUCCESS;
    
    constructor ( public shippingCompany : ShippingCompanyModel[] ) {}
}

export type Actions = 
| AddShippingCompany
| AddShippingCompanyError
| AddShippingCompanySuccess
| GetAllShippingCompany
| GetAllShippingCompanySuccess
| GetAllShippingCompanyError
| RemoveShippingCompany
| RemoveShippingCompanySuccess
| RemoveShippingCompanyError



