import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as emailActions from "./email.actions";
import { map, mergeMap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { EmailRequestService } from "@app/services/email-request.service";
import Swal from "sweetalert2";

@Injectable()
export class EmailEffects {
    createFollowEmail$ = createEffect(() => 
        this.actions$.pipe(
            ofType<emailActions.CreateRequestEmail>(emailActions.REQUEST_EMAIL),
            mergeMap(
                data => this.emailRequestService.createEmailRequest(data).pipe(
                    map(res => {
                        Swal.fire(res.message,'', 'success')
                        return new emailActions.CreateRequestEmailSuccess(res.data) }),
                    catchError( error => {
                        error.message.includes('500') ?  Swal.fire('Não foi possivel acompanhar por email. Tente novamente!','', 'error') : Swal.fire(error.message,'', 'error')
                        return of (new emailActions.CreateRequestEmailError(error))})
              )
            )

        )

    )

    getNotification$ = createEffect(() => 
       this.actions$.pipe(
        ofType<emailActions.getEmailNotification>(emailActions.GET_EMAIL_NOTIFICATION),
        mergeMap(
            data => this.emailRequestService.getEmailRequest(data).pipe(
                map(res => new emailActions.getEmailNotificationSuccess(res)),
                catchError( error => of (new emailActions.getEmailNotificationError(error)))

            )
         )
        )
    )
    
    
    updateNotification$ = createEffect(() => 
       this.actions$.pipe(
        ofType<emailActions.updateNotification>(emailActions.UPDATE_NOTIFICATION_EMAIL),
        mergeMap(
            data => this.emailRequestService.updateNotification(data).pipe(
                map(res => new emailActions.updateNotificationSuccess(res)),
                catchError( error => of (new emailActions.updateNotificationError(error)))

            )
         )
        )
       )
    
  constructor(private actions$: Actions, private emailRequestService : EmailRequestService) {
      
  }

}