import { Component, Input, OnInit } from '@angular/core';
import { AppState } from '@app/app.state';
import { Store } from '@ngrx/store';
import * as emailActions from "@store/email/email.actions";

@Component({
  selector: 'app-modal-email',
  templateUrl: './modal-email.component.html',
  styleUrls: ['./modal-email.component.scss']
})
export class ModalEmailComponent implements OnInit {

  @Input() infos? : any;

  constructor(private store: Store<AppState>) { 
 
  }

  ngOnInit(): void { 
    
  }

  confirmFollowEmail(){
    this.store.dispatch( new emailActions.CreateRequestEmail(this.infos.dataEmailRequest))
  }
}
