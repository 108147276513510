import { AccessManagementModel } from "@app/model/access-management";
import { Action } from "@ngrx/store";

export const ADD_ACCESS_FILTERS = '[ADD FILTERS] Access filters';
export const ADD_ACCESS_FILTERS_ERROR = '[ADD FILTERS ERROR] Access filters error';
export const ADD_ACCESS_FILTERS_SUCCESS = '[ADD FILTERS SUCCESS] Access filters success';

export const REMOVE_ACCESS_FILTERS = '[REMOVE FILTERS] Access filters';
export const REMOVE_ACCESS_FILTERS_ERROR = '[REMOVE FILTERS ERROR] REMOVE Access filters error';
export const REMOVE_ACCESS_FILTERS_SUCCESS = '[REMOVE FILTERS SUCCESS]  REMOVE Access filters success';

export const REMOVE_ALL_ACCESS_FILTERS = '[REMOVE ALL FILTERS] Access filters';
export const REMOVE_ALL_ACCESS_FILTERS_ERROR = '[REMOVE ALL FILTERS ERROR] REMOVE ALL Access filters error';
export const REMOVE_ALL_ACCESS_FILTERS_SUCCESS = '[REMOVE ALL FILTERS SUCCESS]  REMOVE  ALL Access filters success';

export const ACCESS_FILTERS = '[GET FILTERS] Access filters';
export const ACCESS_FILTERS_ERROR = '[FILTERS ERROR] Access filters error';
export const ACCESS_FILTERS_SUCCESS = '[FILTERS SUCCESS] Access filters success';


export class AccessFilters implements Action {
    readonly type = ACCESS_FILTERS;

    constructor (public tipo_categoria : number) {}
}

export class AccessFiltersError implements Action {
    readonly type = ACCESS_FILTERS_ERROR;

    constructor (public payload: any) {}
}

export class  AccessFiltersSuccess implements Action {

    readonly type = ACCESS_FILTERS_SUCCESS;
    
    constructor ( public payload : AccessManagementModel ) {}
}

export class AddAccessFilters implements Action {
    
    readonly type = ADD_ACCESS_FILTERS;

    constructor ( public filters : AccessManagementModel) {}
}

export class AddAccessFiltersError implements Action {
    readonly type = ADD_ACCESS_FILTERS_ERROR;

    constructor (public payload: any) {}
}

export class  AddAccessFilterSuccess implements Action {
    readonly type = ADD_ACCESS_FILTERS_SUCCESS;

    
    constructor ( public filters  : AccessManagementModel ) {}
}


export class RemoveAccessFilters implements Action {
    
    readonly type = REMOVE_ACCESS_FILTERS;

    constructor ( public filters : AccessManagementModel) {}
}

export class RemoveAccessFiltersError implements Action {
    readonly type = REMOVE_ACCESS_FILTERS_ERROR;

    constructor (public payload: any) {}
}

export class  RemoveAccessFilterSuccess implements Action {
    readonly type = REMOVE_ACCESS_FILTERS_SUCCESS;
    
    constructor ( public tipo_categoria : any ) {}
}




export class RemoveAllAccessFilters implements Action {
    
    readonly type = REMOVE_ALL_ACCESS_FILTERS;

    constructor ( public tipo_categoria : number) {}
}

export class RemoveAllAccessFiltersError implements Action {
    readonly type = REMOVE_ALL_ACCESS_FILTERS_ERROR;

    constructor (public payload: any) {}
}

export class  RemoveAllAccessFilterSuccess implements Action {
    readonly type = REMOVE_ALL_ACCESS_FILTERS_SUCCESS;
    
    constructor ( public tipo_categoria : any ) {}
}
export type Actions = 
| AccessFilters
| AccessFiltersSuccess
| AccessFiltersError
| AddAccessFilters
| AddAccessFiltersError
| AddAccessFilterSuccess
| RemoveAccessFilters
| RemoveAccessFiltersError
| RemoveAccessFilterSuccess
| RemoveAllAccessFilters
| RemoveAllAccessFiltersError
| RemoveAllAccessFilterSuccess



