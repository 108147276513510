<div class="row">
  <div class="col-auto">
    <h2 mat-dialog-title translate>modal.confirmFollowEmail</h2>
  </div>
  <div class="col">
    <button class="btn btn-sm btn-outline-info float-right" mat-dialog-close >
      <app-close class="svg-stroke-secondary" size="20"></app-close>
    </button>
  </div>
</div>

<mat-dialog-content  class="modalText">
  <span translate>modal.wantToFollowAll</span>
  <b> {{infos.checkItems}} </b>
  <span translate>modal.requestEmail</span>
  <b> {{infos.email}} </b> ?
</mat-dialog-content>


<mat-dialog-actions class="float-right">
  <button mat-button  class="btn btn-outline-info btn-sm btn-font font-weight-bold mr-3" mat-dialog-close translate>modal.cancel</button>
  <button mat-button class="btn btn-primary btn-sm font-weight-bold" (click)="confirmFollowEmail()" [mat-dialog-close]="true" translate="">modal.confirm</button>
</mat-dialog-actions>

