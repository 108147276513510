import * as emailActions from "./email.actions";

export function EmailReducer (state: any[] = [], action: any) {
    
    switch (action.type) {

        case emailActions.REQUEST_EMAIL:            
            return action;
        
        case emailActions.REQUEST_EMAIL_SUCCESS:
                
            return action;

        case emailActions.REQUEST_EMAIL_ERROR:
                    
            return action;

        case emailActions.GET_EMAIL_NOTIFICATION:            
            return action;
        
        case emailActions.GET_EMAIL_NOTIFICATION_SUCCESS:            
            return { notification: action.payload.data};
        
        case emailActions.GET_EMAIL_NOTIFICATION_ERROR:            
            return action;
        

        case emailActions.UPDATE_NOTIFICATION_EMAIL:            
            return action;
        
        case emailActions.UPDATE_NOTIFICATION_EMAIL_SUCCESS:            
            return { notification: action.payload.data};
        
        case emailActions.UPDATE_NOTIFICATION_EMAIL_ERROR:            
            return action;
            
        default :
            return state

    
    }
}