import { Component, Input, OnInit } from '@angular/core';
import { TrackingRequestsService } from '../../tracking-requests.service';

@Component({
  selector: 'app-order-flow',
  templateUrl: './order-flow.component.html',
  styleUrls: ['./order-flow.component.scss']
})
export class OrderFlowComponent implements OnInit {
  @Input('order') order: any
  @Input('item') item:  any
  @Input('items') items:  any
  @Input('index') index:  any
  @Input('traffic') traffic:  any

  verifyStatus : Boolean = true;
  orderStatus = ['EM LIBERAÇÃO', 'PEDIDO EMITIDO', 'CANCELADO', 'FATURADO']
  constructor(private trackingRequestsService : TrackingRequestsService) { }

  ngOnInit(): void {

    if(this.order?.status_pedido ){
      this.verifyStatus  = this.trackingRequestsService.verifyStatus(this.order?.status_pedido, this.orderStatus)
    }

  }
}
