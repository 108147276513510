<div class="d-flex pt-3">
    
    <div *ngIf="(item.status_pedido | uppercase) == 'EM LIBERAÇÃO' &&  !item?.faturas?.$values[0]?.num_fatura && !item?.faturas?.$values[0]?.status_fatura && !item?.faturas?.$values[0]?.status_recebimento" class="col-1 mr-lg-3 mr-3  ml-lg-1">             
        <app-progress size="32" class1="svg-success" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
    </div>

    <div *ngIf="item.iconterms_pedido != 'CIF'">

        <div *ngIf="(item.status_pedido  | uppercase) == 'PEDIDO EMITIDO' &&  !item?.faturas?.$values[0]?.num_fatura && !item?.faturas?.$values[0]?.status_fatura && !item?.faturas?.$values[0]?.status_recebimento" class="col-1 mr-lg-3 mr-3  ml-lg-1">             
            <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-default-green"></app-progress>
        </div>
    
        <div *ngIf="item.faturas?.$values[0]?.num_fatura && !item?.faturas?.$values[0]?.status_fatura && !item?.faturas?.$values[0]?.status_recebimento" class="col-1 mr-lg-3 mr-3  ml-lg-1">             
            <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
        </div>
    
        <div *ngIf="(item.status_pedido  | uppercase) == 'CANCELADO' && !item?.faturas?.$values[0]?.num_fatura && !item?.faturas?.$values[0]?.status_fatura  && !item?.faturas?.$values[0]?.status_fatura && !item?.faturas?.$values[0]?.status_recebimento " class="col-1 mr-lg-3 mr-3  ml-lg-1">             
            <app-progress size="32" class1="svg-danger" class2="svg-danger" class3="svg-danger" class4="svg-danger"></app-progress>
        </div>
    </div>

    

    <div *ngIf="item.iconterms_pedido == 'CIF'">

        <div *ngIf="(item.status_pedido  | uppercase) == 'PEDIDO EMITIDO' &&  !item?.faturas?.$values[0]?.num_fatura  && !item?.faturas?.$values[0]?.status_recebimento" class="col-1 mr-lg-3 mr-3  ml-lg-1">             
            <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-default-green"></app-progress>
        </div>
    
        <div *ngIf="item.faturas?.$values[0]?.num_fatura  && !item?.faturas?.$values[0]?.status_recebimento" class="col-1 mr-lg-3 mr-3  ml-lg-1">             
            <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
        </div>
    
        <div *ngIf="(item.status_pedido  | uppercase) == 'CANCELADO' && !item?.faturas?.$values[0]?.num_fatura && !item?.faturas?.$values[0]?.status_recebimento " class="col-1 mr-lg-3 mr-3  ml-lg-1">             
            <app-progress size="32" class1="svg-danger" class2="svg-danger" class3="svg-danger" class4="svg-danger"></app-progress>
        </div>
    </div>
 

    <div  class="col-8 equest__status font-14">
        <div *ngIf="item.iconterms_pedido != 'CIF'">
            <div *ngIf="(item.status_pedido  | uppercase) == 'EM LIBERAÇÃO' &&  !item?.faturas?.$values[0]?.num_fatura && !item?.faturas?.$values[0]?.status_fatura  && !item?.faturas?.$values[0]?.status_fatura && !item?.faturas?.$values[0]?.status_recebimento ">
            
                <b class="request__status__success uppercase" translate>generic.order</b>
                <br>
                                        
                <span> {{item.status_pedido}}  </span>
                
            </div>
              
            <div class=" ml-n4 pl-n3 " *ngIf="(item.status_pedido | uppercase) == 'PEDIDO EMITIDO' &&  !item?.faturas?.$values[0]?.num_fatura && !item?.faturas?.$values[0]?.status_fatura && !item?.faturas?.$values[0]?.status_recebimento ">
                
                <b class="request__status__success uppercase">{{item.status_pedido}}</b>
                <br>
                                        
                <span> {{item.dt_emissao_pedido | date: 'dd/MM/yyyy'}} </span>
                
            </div>

            <div *ngIf="item.faturas?.$values[0]?.num_fatura &&  !item?.faturas?.$values[0]?.status_fatura && !item?.faturas?.$values[0]?.status_recebimento">
                
                <b class="request__status__success uppercase" translate>request.billed</b>
                <br>
                                        
                <span> {{item.faturas?.$values[0]?.dt_fatura | date: 'dd/MM/yyyy'}} </span>
                

            </div> 

            <div class=" ml-n4 pl-n3 "  *ngIf="(item.status_pedido | uppercase) == 'CANCELADO' &&   !item?.faturas?.$values[0]?.num_fatura && !item?.faturas?.$values[0]?.status_fatura && !item?.faturas?.$values[0]?.status_recebimento ">
                
                <b class="request__status__success text-danger uppercase">{{ item.status_pedido}}</b>
                <br>
                                        
                <span> {{item.dt_cancelamento_pedido | date: 'dd/MM/yyyy'}} </span>
                

            </div> 

        </div>
   


        <div *ngIf="item.iconterms_pedido == 'CIF'">
            <div *ngIf="(item.status_pedido  | uppercase) == 'EM LIBERAÇÃO' &&  !item?.faturas?.$values[0]?.num_fatura  && !item?.faturas?.$values[0]?.status_recebimento ">
            
                <b class="request__status__success uppercase" translate>generic.order</b>
                <br>
                                        
                <span> {{item.status_pedido}}  </span>
                
            </div>
              
            <div class=" ml-n4 pl-n3 "  *ngIf="(item.status_pedido | uppercase) == 'PEDIDO EMITIDO' &&  !item?.faturas?.$values[0]?.num_fatura && !item?.faturas?.$values[0]?.status_recebimento ">
                
                <b class="request__status__success uppercase">{{item.status_pedido}}</b>
                <br>
                                        
                <span> {{item.dt_emissao_pedido | date: 'dd/MM/yyyy'}} </span>
                
            </div>

            <div class="ml-n4 " *ngIf="item.faturas?.$values[0]?.num_fatura && !item?.faturas?.$values[0]?.status_recebimento">
                
                <b class="request__status__success uppercase" translate>request.billed</b>
                <br>
                                        
                <span> {{item.faturas?.$values[0]?.dt_fatura | date: 'dd/MM/yyyy'}} </span>
                

            </div> 

            <div class=" ml-n4 pl-n3 "  *ngIf="(item.status_pedido | uppercase) == 'CANCELADO' &&   !item?.faturas?.$values[0]?.num_fatura  && !item?.faturas?.$values[0]?.status_recebimento ">
                
                <b class="request__status__success text-danger uppercase">{{ item.status_pedido}}</b>
                <br>
                                        
                <span> {{item.dt_cancelamento_pedido | date: 'dd/MM/yyyy'}} </span>
                

            </div> 

        </div>
   


       
    </div>


</div>