<div class="row pt-3 pl-3">
    <div *ngIf="item.tipo_processo != 'COUPA' " >              
        <div *ngIf="!verifyStatus && (item.status_rc | uppercase) != 'CANCELADO' "   class="col-1 mr-lg-3 mr-3  ml-lg-1">             
            <app-progress size="32" class1="svg-default-green" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </div>
        
        <span *ngIf="(item.status_rc | uppercase) == 'AGUARDANDO LIBERAÇÃO' && (item.status_rc | uppercase) != 'CANCELADO' && (item.status_rc | uppercase) != 'BLOQUEADA' " class="col-1 mr-lg-2 mr-3  ml-lg-1">
            <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </span>

        
        <span *ngIf="(item.status_rc | uppercase) == 'APROVADA' && (item.status_rc | uppercase) != 'CANCELADO' && (item.status_rc | uppercase) != 'BLOQUEADA' " class="col-1 mr-lg-2 mr-3  ml-lg-1">
            <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
        </span>

        <span *ngIf="(item.status_rc | uppercase) == 'BLOQUEADA' || (item.status_rc | uppercase) == 'CANCELADO'" class="col-1 mr-lg-2 mr-3  ml-lg-1">
            <app-progress size="32" class1="svg-danger" class2="svg-danger" class3="svg-danger" class4="svg-danger"></app-progress>
        </span>
    </div>

    <div *ngIf="item.tipo_processo == 'COUPA'">              
        <div *ngIf="!verifyStatusCoupa"   class="col-1 mr-lg-3 mr-3  ml-lg-1">             
            <app-progress size="32" class1="svg-default-green" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </div>

        <span *ngIf="(item.status_rc | uppercase) == 'RASCUNHO' "  class="col-1 mr-lg-2 mr-3  ml-lg-1">
            <app-progress size="32" class1="svg-success" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </span>
        


        <span *ngIf="(item.status_rc | uppercase) == 'AGUARDANDO LIBERAÇÃO'" class="col-1 mr-lg-2 mr-3  ml-lg-1">
            <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </span>

        <span *ngIf=" (item.status_rc | uppercase) == 'EM PROCESSAMENTO'"  class="col-1 mr-lg-2 mr-3  ml-lg-1">
            <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-default-green"></app-progress>
        </span>

        <span *ngIf="(item.status_rc | uppercase) == 'PROCESSADA'"  class="col-1 mr-lg-2 mr-3  ml-lg-1">
            <app-progress size="32" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
        </span>

        <span  *ngIf="(item.status_rc | uppercase) == 'BLOQUEADA'"  class="col-1 mr-lg-2 mr-3  ml-lg-1">
            <app-progress size="32" class1="svg-danger" class2="svg-danger" class3="svg-danger" class4="svg-danger"></app-progress>
        </span>
    </div>



    <div  class="col-8 request__status font-14 ml-n4">
   
        <b class="request__status__success uppercase" [ngClass]="{'text-danger': (item.status_rc | uppercase) == 'BLOQUEADA' || (item.status_rc | uppercase) == 'CANCELADO'}" *ngIf="item.status_rc" translate> {{item.status_rc}} </b>
       
        <br>

        <div *ngIf="item.tipo_processo != 'COUPA'">

            <div *ngIf="(item.status_rc | uppercase) == 'AGUARDANDO LIBERAÇÃO' && (item.status_rc | uppercase) != 'CANCELADO'  &&  (item.status_rc | uppercase) != 'BLOQUEADA'">
                <span translate> request.createRc </span>: {{item.dt_criacao_rc | date: 'dd/MM/yyyy'}}
            </div>

            <div *ngIf="(item.status_rc | uppercase) == 'APROVADA' && (item.status_rc | uppercase) != 'CANCELADO'  &&  (item.status_rc | uppercase) != 'BLOQUEADA' ">
                <span translate> request.deadline </span>: {{item.prazo_entrega_rc}} <span translate>generic.days</span>
            </div>

            <div *ngIf="item.bloqueio_rc && (item.status_rc | uppercase) != 'CANCELADO'">
                {{item.dt_bloqueio_rc | date: 'dd/MM/yyyy'}}
            </div>

            <div *ngIf="(item.status_rc | uppercase) == 'CANCELADO'">
                
                {{item.dt_cancelamento_rc | date: 'dd/MM/yyyy'}}
            </div>


        </div>

        <div *ngIf=" item.tipo_processo == 'COUPA'">
            <div *ngIf="(item.status_rc | uppercase) == 'AGUARDANDO LIBERAÇÃO' && item.dt_criacao_rc">
                <span translate> request.createRc </span>: {{item.dt_criacao_rc | date: 'dd/MM/yyyy'}}
            </div>

            <div *ngIf="(item.status_rc | uppercase) == 'EM PROCESSAMENTO'" >
                <span translate> request.deadline </span>: {{item.prazo_entrega_rc}}
            </div>

            <div *ngIf="(item.status_rc | uppercase) == 'PROCESSADA'">
                {{item.dt_aprovacao_rc | date: 'dd/MM/yyyy'}}
            </div>

            <div *ngIf="(item.status_rc | uppercase) == 'BLOQUEADA'">
                {{item.dt_bloqueio_rc | date: 'dd/MM/yyyy'}}
            </div>


        </div>

    
    </div>


</div>