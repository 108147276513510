import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MicrosoftService } from './services/microsoft.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title : string = 'braskem';
  isIframe = false;

  constructor (private translate: TranslateService, 
    private microsoft: MicrosoftService
    ) {
    this.translate.setDefaultLang('pt')

  }


  
  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.microsoft.login();
  }

  
   
 
}
