import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseAPI } from '@app/constants';
import { Observable } from 'rxjs';
import { authPasswordProd } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient) {     
  }

  

  authToken() : Observable<any>{
    return this.httpClient.post(baseAPI + '/v1/auth/login', {login : 'braskem', password : authPasswordProd})

  }
}
