<b class="request__status__title font-weight-bold mt-3" translate> generic.order</b>
<div class="row mt-2 pt-1">
    <div *ngIf="!order?.status_pedido || (!verifyStatus && !order?.faturas?.$values[0]?.num_fatura)  ">
        <span class="col-1 mr-n3  pt-1" >
            <span class="position-absolute mt-3 ml-3">0/3</span>
            <app-progress size="50" class1="svg-default-green" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </span>  
    </div>

    <div *ngIf="(order?.status_pedido | uppercase) == 'EM LIBERAÇÃO' && !order?.faturas?.$values[0]?.num_fatura" >
        <span class="col-1 pt-1 mr-n3">
            <span class="position-absolute mt-3 ml-3">1/3</span>
            <app-progress size="50" class1="svg-success" class2="svg-default-green" class3="svg-default-green" class4="svg-default-green"></app-progress>
        </span>  
    </div>


    <div *ngIf="((order?.status_pedido | uppercase) == 'PEDIDO EMITIDO'  && !order?.faturas?.$values[0]?.num_fatura) || ((order?.status_pedido | uppercase) == 'FATURADO'  && !order?.faturas?.$values[0]?.num_fatura) ">
        <span class="col-1 pt-1 mr-n3">
            <span class="position-absolute mt-3 ml-3">2/3</span>
            <app-progress size="50" class1="svg-success" class2="svg-success" class3="svg-success" class4="svg-default-green"></app-progress>
        </span>  
    </div>

     <div *ngIf="order?.faturas?.$values[0]?.num_fatura && (order.status_pedido | uppercase) != 'CANCELADO'">
        <span class="col-1 mr-n3 pt-1 ">
            <span class="position-absolute mt-3 ml-3">3/3</span>
            <app-progress size="50" class1="svg-success
            " class2="svg-success" class3="svg-success" class4="svg-success"></app-progress>
        </span>  
    </div>

    <span *ngIf="order?.faturas?.$values[0]?.num_fatura && (order.status_pedido | uppercase) == 'CANCELADO'" class="ml-1" >
        <span class="col-1 mr-n3 pt-1 ">
            <span class="position-absolute mt-3 ml-3">3/3</span>
            <app-progress size="50" class1="svg-danger" class2="svg-danger" class3="svg-danger" class4="svg-danger"></app-progress>    
        </span> 
      
    </span>

    <span *ngIf="order?.dt_pedido && (order.status_pedido | uppercase) == 'CANCELADO' && !order?.faturas?.$values[0]?.num_fatura " class="ml-1">
        <span class="col-1 mr-n3 pt-1 ">
            <span class="position-absolute mt-3 ml-3">2/3</span>
            <app-progress size="50" class1="svg-danger" class2="svg-danger" class3="svg-default-danger" class4="svg-default-danger"></app-progress>
    
        </span>
      
    </span>
 
    <span *ngIf="!order?.dt_pedido  && (order.status_pedido | uppercase) == 'CANCELADO' " class="ml-1">
        <span class="col-1 mr-n3 pt-1 ">
            <span class="position-absolute mt-3 ml-3">1/3</span>
            <app-progress size="50" class1="svg-danger" class2="svg-danger" class3="svg-default-danger" class4="svg-default-danger"></app-progress>
          
        </span>   
       
    </span>
 


    <span *ngIf="!order?.faturas?.$values[0]?.num_fatura" class="col mt-3 ml-n3" >
        <app-progress-line class="svg-default-green" ></app-progress-line>
    </span>

    <span  *ngIf="order?.faturas?.$values[0]?.num_fatura" class="col mt-3 ml-n3" >
        <app-progress-line class="svg-success" ></app-progress-line>
    </span>
</div>
<!-- *ngIf="order?.cod_liberacao_pedido" -->
<div *ngIf="order?.status_pedido && verifyStatus">
    <div class="row">
        <div class="col">
            <app-vertical-line size="55" class="svg-success"></app-vertical-line>
        </div>
    </div>

    <div class="d-flex" >
        <div class="pl-4 mt-n2">
            <app-circle size="8" class="svg-success"></app-circle>
        </div>
    
        <div class="request__status__text font-24 ml-2" [ngClass]="{'request__status__textCurrent': (order?.status_pedido | uppercase) == 'EM LIBERAÇÃO'   }" >
           <span translate> request.onRelease</span> 
        </div>
    </div>
</div>

 <!-- status issued -->
 <div *ngIf="(order?.status_pedido | uppercase) == 'PEDIDO EMITIDO'  || order?.faturas?.$values[0]?.num_fatura">
     <div class="row  mt-n4">
         <div class="col">
             <app-vertical-line size="55" class="svg-success"></app-vertical-line>
         </div>
     </div>

     <div class="d-flex" >
         <div class="pl-4 mt-n2">
             <app-circle size="8" class="svg-success"></app-circle>
         </div>
 

         <div class="request__status__text font-24 ml-2"  [ngClass]="{'request__status__textCurrent': (order?.status_pedido | uppercase) == 'PEDIDO EMITIDO'  && !order?.faturas?.$values[0]?.num_fatura  }"  >
             
             <span  [ngClass]="{'font-18': (order?.status_pedido | uppercase) == 'PEDIDO EMITIDO' && !order?.faturas?.$values[0]?.num_fatura    }" translate>request.issued</span>
            
             <br>
             
             <div class="font-weight-bold">
                <span translate>request.issuedPC</span> : {{order?.dt_aprovacao_pedido |  date: 'dd/MM/yyyy'}}
             </div>

             <div >
                <div *ngIf="order?.dt_remessa_pedido && (order?.status_pedido | uppercase) == 'PEDIDO EMITIDO' && !order?.faturas?.$values[0]?.num_fatura   ">
                    <span translate>request.shipmentDate</span> : {{order?.dt_remessa_pedido |  date: 'dd/MM/yyyy'}}
                </div>

                <div [ngClass]="{'font-weight-bold': order?.faturas?.$values[0]?.num_fatura}" *ngIf="order?.iconterms_pedido ">
                    <span translate>request.freight</span> {{order?.iconterms_pedido}}
                </div>

                <div *ngIf=" (order?.status_pedido | uppercase) == 'PEDIDO EMITIDO' && !order?.faturas?.$values[0]?.num_fatura  && (order?.faturas?.$values.length > 0 || order?.faturas?.$values[0]?.num_fatura) ">
                    <div *ngIf="order?.faturas?.$values[0]?.dt_previsao_entrega">
                        <span translate>request.deliveryForecast </span> 
                        <span translate *ngIf="order?.iconterms_pedido != 'CIF'">generic.shippingCompanyDelivery </span> 
                        <span *ngIf="order?.iconterms_pedido == 'CIF'"> Braskem</span>
                        {{order?.faturas.$values[0].dt_previsao_entrega |  date: 'dd/MM/yyyy'}}
                   </div> 
                </div>
           
             </div>
         
         

             
         </div>

     </div>

 </div>


<!-- status billed -->
<div *ngIf="order?.faturas?.$values[0]?.num_fatura">

    <div class="row">
        <div class="col mt-n5">
            <app-vertical-line size="55" class="svg-success"></app-vertical-line>
        </div>
    </div>

    <div class="d-flex pb-5">
        <div class="pl-4 mt-n2">
            <app-circle size="8" class="svg-success"></app-circle>
        </div>

        <div class="request__status__text request__status__textCurrent ml-2"  >
            
            <span  class="font-18 font-weight-bold" translate> request.billed</span> 
            <br>

            <div class="font-weight-bold" *ngFor="let fatura of order?.faturas?.$values">
                <span>{{fatura.num_fatura}}</span>
                <br>

                <span>{{fatura.dt_fatura |  date: 'dd/MM/yyyy'}}</span> | {{fatura.quant_fatura}}  {{order?.unidade_pedido}}

                <div *ngIf="(order.status_pedido | uppercase) != 'CANCELADO'" >
                    <span translate>request.deliveryForecast </span> 
                    <span translate *ngIf="order?.iconterms_pedido != 'CIF'"> generic.shippingCompanyDelivery </span> 
                    <span *ngIf="order?.iconterms_pedido == 'CIF'">Braskem</span>
                    {{fatura.dt_previsao_entrega | date: 'dd/MM/yyyy'}}
                </div>
            </div>
        
        </div>

    </div>                                                                 
</div>



<div *ngIf="(order.status_pedido | uppercase) == 'CANCELADO' ">

    <div class="row" [ngClass]="{'mt-n5' : order?.faturas?.$values[0]?.num_fatura }">
        <div class="col mt-n4" [ngClass]="{'mt-n5' : order?.faturas?.$values[0]?.num_fatura }">
            <app-vertical-line size="55" class="svg-success"></app-vertical-line>
        </div>
    </div>
    
    <div class="d-flex" >
        <div class="pl-4 mt-n2">
            <app-circle size="8" class="svg-success"></app-circle>
        </div>
        <div >
            <b class="text-danger font-18 ml-1" translate>request.canceled</b>
            <br>
            <b class="text-danger ml-1">
                <span  translate>request.dateCanceled</span>
                <span> {{order.dt_cancelamento_pedido | date: 'dd/MM/yyyy'}}</span>
            </b>

        </div>
    </div>

</div>