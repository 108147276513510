import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-traffic-status',
  templateUrl: './traffic-status.component.html',
  styleUrls: ['./traffic-status.component.scss']
})
export class TrafficStatusComponent implements OnInit {
  @Input() item :any;
  @Input() order: any;
  constructor() { }

  ngOnInit(): void {
  }

}
