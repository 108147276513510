import { Action } from "@ngrx/store";
export const REQUEST_EMAIL = '[REQUEST EMAIL] Request email';
export const REQUEST_EMAIL_ERROR = '[REQUEST EMAIL ERROR] Request email error';
export const REQUEST_EMAIL_SUCCESS = '[REQUEST EMAIL SUCCESS] Request email success';
export const GET_EMAIL_NOTIFICATION= '[GET EMAIL NOTIFICATION] Get email notification ';
export const GET_EMAIL_NOTIFICATION_SUCCESS= '[GET EMAIL NOTIFICATION SUCCESS] Get email notification success';
export const GET_EMAIL_NOTIFICATION_ERROR = '[GET EMAIL NOTIFICATION ERROR] Get email notification error';
export const UPDATE_NOTIFICATION_EMAIL = '[UPDATE NOTIFICATION EMAIL] Update notification email';
export const UPDATE_NOTIFICATION_EMAIL_SUCCESS = '[UPDATE NOTIFICATION EMAIL SUCCESS] Update notification email success';
export const UPDATE_NOTIFICATION_EMAIL_ERROR = '[UPDATE NOTIFICATION EMAIL ERROR] Update notification email error';


export class CreateRequestEmail implements Action {
    readonly type = REQUEST_EMAIL;

    constructor ( public request? : any ) {}
}

export class CreateRequestEmailError implements Action {
    readonly type = REQUEST_EMAIL_ERROR;

    constructor ( public request? : any ) {}
}


export class CreateRequestEmailSuccess implements Action {
    readonly type = REQUEST_EMAIL_ERROR;

    constructor ( public request? : any ) {}
}


export class getEmailNotification implements Action{
    readonly type = GET_EMAIL_NOTIFICATION;

    constructor ( public email? : any ) {}

}

export class getEmailNotificationSuccess implements Action{
    readonly type = GET_EMAIL_NOTIFICATION_SUCCESS;

    constructor ( public payload : any ) {}

}

export class getEmailNotificationError implements Action{
    readonly type = GET_EMAIL_NOTIFICATION_ERROR;

    constructor ( public payload? : any ) {}

}

export class updateNotification implements Action{
    readonly type = UPDATE_NOTIFICATION_EMAIL;

    constructor ( public email : any ) {}

}

export class updateNotificationSuccess implements Action{
    readonly type = UPDATE_NOTIFICATION_EMAIL_SUCCESS;

    constructor ( public payload? : any ) {}

}

export class updateNotificationError implements Action{
    readonly type = UPDATE_NOTIFICATION_EMAIL_ERROR;

    constructor ( public payload? : any ) {}

}